export default function MultiProgress({ segments, total, remainderLabel }) {
  const segmentTotal = segments.reduce((sum, segment) => sum + segment.value, 0);
  const totalValue = total || segmentTotal;

  return (
    <div className="w-full mx-auto">
      <div className="mb-2 flex h-4 overflow-hidden rounded bg-white w-full">
        {segments.map((segment, index) => (
          segment.value
            ? <div
                key={index}
                className={`h-full ${segment.color} rounded-lg me-1.5`}
                style={{ width: `${(segment.value / totalValue) * 100}%` }}
              />
          : ""
        ))}
        {totalValue !== segmentTotal
         && <div
              className={`h-full bg-gray-300 rounded-lg me-1`}
              style={{ width: `${totalValue - segmentTotal}%` }}
            />}
      </div>
      <div className="flex flex-wrap mt-2 w-full">
        {segments.map((segment, index) => (
          segment.value
            ? <div key={index} className="flex items-center mr-6 mb-2">
              <div className={`w-3 h-3 ${segment.color} rounded-full mr-1`} />
              <span className="text-xs font-semibold text-gray-700">
                {segment.label}: {segment.value}
              </span>
            </div>
          : ""
        ))}
        {totalValue !== segmentTotal
         && <div className="flex items-center mr-4 mb-2">
              <div className={`w-3 h-3 bg-gray-300 rounded-full mr-1`} />
              <span className="text-xs font-semibold text-gray-700">
                {remainderLabel || 'Remainder'} : {totalValue - segmentTotal}
              </span>
            </div>}
      </div>
    </div>
  );
};
