import { useQuery } from "@tanstack/react-query";

import React from "react";

import { Card } from "flowbite-react";
import Header from "components/Header";
import AiQuery from "components/AiQuery";
import Spinner from "components/Spinner";
import ProgressBars from "pages/Home/ProgressBars";

import { apiFetch } from "utils/api";

export default function HomePage() {
  const { isPending, error, data } = useQuery({
    queryKey: ["orgInfo"],
    queryFn: () => apiFetch("v0/info"),
  });

  console.log(data);

  return (
    <div className="gap-6 pb-12">
      <Header className="mb-6">Home</Header>
      <Card
        className="rounded-lg mb-6 transition"
        style={{ minHeight: "237px" }}
      >
        {isPending ? (
          <div className="flex flex-row min-h-[180px] justify-center items-center text-center text-gray-400">
            <Spinner className="text-gray-400" />
          </div>
        ) : (
          <ProgressBars error={error} data={data} />
        )}
      </Card>
      <AiQuery className="" />
    </div>
  );
}
