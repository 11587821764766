import { Badge as FrBadge } from "flowbite-react";

import {
  FaEye,
  FaEdit,
  FaUpload,
  FaPlus,
  FaRegClock,
  FaPauseCircle,
  FaCheck,
  FaFirstdraft,
  FaExclamationCircle,
  FaExclamationTriangle,
} from "react-icons/fa";

const ICONS = {
  clock: FaRegClock,
  eye: FaEye,
  edit: FaEdit,
  upload: FaUpload,
  plus: FaPlus,
  check: FaCheck,
  draft: FaFirstdraft,
  error: FaExclamationCircle,
  warning: FaExclamationTriangle,
  pause: FaPauseCircle,
};

const COLORS = {
  gray: "gray",
  yellow: "warning",
  red: "red",
  green: "success",
};

export default function Badge({ icon, text, color, className, children }) {
  return (
    <FrBadge
      icon={icon ? ICONS[icon] || icon : undefined}
      color={color ? COLORS[color] : "gray"}
      className={`py-1 px-2.5 w-fit font-medium rounded whitespace-nowrap justify-center ${className}`}
    >
      {children || text || ""}
    </FrBadge>
  );
}
