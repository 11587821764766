import React from "react";

import { Outlet } from "react-router-dom";
import { SideNav } from "components/sidenav/index";

export const Layout = () => {
  return (
    <div className="flex flex-wrap w-full">
      <SideNav />
      <div className="flex-1 p-8 overflow-scroll h-screen bg-gray-100">
        <Outlet />
      </div>
    </div>
  );
};
