import React from "react";

export default function LetterIcon({
  name = "",
  backgroundColor = "bg-gray-900",
  textColor = "text-white",
  className = "w-10 h-10",
}) {
  const firstLetter = () => {
    return Array.from(name)[0];
  };
  return (
    <div
      className={`flex justify-center items-center uppercase ${className} ${textColor} ${backgroundColor}`}
    >
      {firstLetter()}
    </div>
  );
}
