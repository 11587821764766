import React, { useState, useEffect } from "react";

import { Badge, Accordion, Label, TextInput } from "flowbite-react";
import Button from "components/Button";

import { getToken } from "utils/util.js";
import { apiFetch } from "utils/api";

function FleetServerSetup() {
  const [formData, setFormData] = useState({
    url: "",
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Data' , formData);
    apiFetch("v0/org/device/setup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
  };

  return (
    <form >
      <hr />
      <div>
        <div><Label>Website URL</Label></div>
        <TextInput
          name="url"
          value={formData.url}
          onChange={handleChange}
          placeholder="Enter URL"
          required
        />
      </div>

      <div>
        <div><Label>Email address</Label></div>
        <TextInput
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Enter email"
          required
        />
      </div>

      <div>
        <div><Label>Password</Label></div>
        <TextInput
          name="password"
          type="password"
          value={formData.password}
          onChange={handleChange}
          placeholder="Password"
          required
        />
      </div>

      <Button variant="primary" type="submit" onClick={handleSubmit} className="my-2">
        Submit
      </Button>
    </form>
  );
}

export default function AssetInventoryPage() {
  const [devicesFetchRes, setDevicesFetch] = useState(null);

  const jwt = getToken();

  const shouldShowServerInterface = jwt.user.scopes.includes("godlike");

  useEffect(() => {
    if (devicesFetchRes === null) {
      apiFetch("v0/org/device").then((res) => {
        console.log(res);
        setDevicesFetch(res);
      });
    }
  }, [devicesFetchRes]);

  function Device({ device }) {
    return <pre>{JSON.stringify(device, null, 2)}</pre>;
  }

  return (
    <>
      {devicesFetchRes && devicesFetchRes.download_links ? (
        <ul>
          {Object.entries(devicesFetchRes.download_links).map(
            ([platform, link], ix) => (
              <li key={`download-link-${ix}`}>
                <a href={link}>{platform}</a>
              </li>
            ),
          )}
        </ul>
      ) : (
        ""
      )}
      <Accordion>
        {((devicesFetchRes || {}).devices || []).map((device, ix) => (
          <Accordion.Panel eventKey={ix} key={`device-${ix}`}>
            <Accordion.Title>
              <div className="mx-2">{device.id}</div>
              <b className="mx-2">
                {device.hostname || (device.metadata.system_info || {}).computer_name}
              </b>
              <Badge pill className="mx-2">
                {device.reports.length}
              </Badge>
            </Accordion.Title>
            <Accordion.Content>
              <Device device={device} />
            </Accordion.Content>
          </Accordion.Panel>
        ))}
      </Accordion>
      {shouldShowServerInterface && <FleetServerSetup />}
    </>
  );
}
