export default function LabelRow({ labels, values, w }) {
  return (
    <div className="flex flex-row w-full flex-wrap">
      {values.map((val, ix) => (
        <div key={labels[ix]} className={w || "w-1/4 mb-4"}>
          <label className="text-xxs uppercase font-semibold text-gray-500 mb-1">
            {labels[ix] || ""}
          </label>
          <div class="text-sm">{val}</div>
        </div>
      ))}
    </div>
  );
}
