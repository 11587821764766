import React, { useState } from "react";
import "dist/tailwind.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { getRawToken } from "./utils/util.js";

import { AuthContext } from "components/AuthContext";
import { routes } from "Routes";
import { Toaster } from "sonner";

const queryClient = new QueryClient();
const router = createBrowserRouter(routes);

function App() {
  const [token, setToken] = useState(getRawToken());

  return (
    <div className="App min-h-screen z-0">
      <Toaster />
      <QueryClientProvider client={queryClient}>
        <AuthContext.Provider value={{ token, setToken }}>
          <RouterProvider router={router} />
        </AuthContext.Provider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
