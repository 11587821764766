import { Alert, Badge, Progress } from "flowbite-react";
import MultiProgress from "components/MultiProgress";
import Header from "components/Header";
import soc2_icon from "images/soc2-icon.jpg";

export default function ProgressBars({ error, data }) {
  if (error !== null) {
    return (
      <Alert color="failure">
        <pre>{JSON.stringify(error, null, 2)} </pre>
      </Alert>
    );
  }

  const reqs = data.controls;
  const signed_off = (data.policies || []).filter(
    (p) => Object.keys(p.sign_offs).length,
  );
  const policies = Object.keys(reqs).filter((k) => k.startsWith("POL-"));
  const controls = Object.keys(reqs).filter((k) => !k.startsWith("POL-"));

  const progress = {
    policies: {
      success: signed_off.length,
      warning: data.policies.length - signed_off.length,
      total: policies.length,
    },
    controls: {
      success: (data.evidence || []).filter((c) => c.passed).length,
      danger: (data.evidence || []).filter((c) => !c.passed).length,
      total: controls.length,
    },
  };
  progress.total = {
    success: progress.policies.success + progress.controls.success,
    danger: 1 + progress.controls.danger,
    total: policies.length + controls.length,
  };

  return (
    <>
      <div className="flex items-center">
        <img src={soc2_icon} alt="SOC2 certification icon" className="w-12" />
        <Header lv={2} className="ml-3 font-medium text-lg">
          Your SOC2 Progress
        </Header>
        <Badge color="success" className="ml-3 py-1 px-3">
          {progress.total.success}% COMPLETE
        </Badge>
      </div>

      <Progress
        theme={{
          color: {
            dark: "bg-gradient-to-r from-black to-green-500 ring-8 ring-white",
          },
        }}
        progress={progress.total.success}
        size="xl"
        color="dark"
      />

      <div className="flex flex-wrap mt-2">
        <div className="w-1/2">
          <div className="w-11/12">
            <Header lv={5} className="mb-2">
              Controls progress
            </Header>
            <MultiProgress
              total={progress.controls.total}
              segments={[
                {
                  label: "READY",
                  value: progress.controls.success,
                  color: "bg-green-600",
                },
                {
                  label: "ISSUE DETECTED",
                  value: progress.controls.danger,
                  color: "bg-red-600",
                },
                { label: "DRAFT", value: 45, color: "bg-blue-600" },
              ]}
              remainderLabel="INCOMPLETE"
            />
          </div>
        </div>
        <div className="w-1/2">
          <Header lv={5} className="mb-2">
            Policies progress
          </Header>
          <MultiProgress
            total={progress.policies.total}
            segments={[
              {
                label: "PUBLISHED",
                value: progress.policies.success,
                color: "bg-green-500",
              },
              {
                label: "DRAFT",
                value: progress.policies.warning,
                color: "bg-blue-600",
              },
            ]}
            remainderLabel="NOT STARTED"
          />
        </div>
      </div>
    </>
  );
}
