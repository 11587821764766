import { ImSpinner } from "react-icons/im";

export default function Spinner({ className }) {
  return (
    <ImSpinner
      className={`${className}`}
      style={{ animation: "spin 2s linear infinite" }}
    />
  );
}
