import * as React from "react";

import MycroftLogo from "components/MycroftLogo";
import { FaRegUser } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { Dropdown, DropdownItem } from "flowbite-react";
import Nav from "./nav";
import UserInfo from "components/UserInfo";
import { useNavigate } from "react-router-dom";
import { clearToken, setToken } from "utils/util";

const mockOrganizations = [
  {
    label: "Mycroft AI",
    email: "mycroft@ai",
    icon: <img className="size-6 rounded-sm" src="https://picsum.photos/200" />,
  },
];

export function SideNav({ organizations = mockOrganizations }) {
  const navigate = useNavigate();

  const handleLogout = () => {
    setToken(null);
    navigate("/");
    clearToken();
  };

  return (
    <div className="flex flex-col min-w-250 h-full bg-custom-dark-green relative min-h-screen justify-items-start">
      {/* Logo */}
      <div className="flex shrink px-6 py-4">
        <div>
          <MycroftLogo width="100" height="24" fill="#FFFFFF" />
        </div>

        {/* TODO: re-add organization switcher */}
      </div>

      {/* Navigation items */}
      <div className="flex flex-1 py-4x justify-self-stretch h-auto overflow-y-auto">
        <Nav />
      </div>

      {/* User profile section */}
      <div className="flex flex-col justify-self-end">
        <Dropdown
          label=""
          dismissOnClick={false}
          renderTrigger={() => (
            <span className="cursor-pointer">
              <UserInfo />
            </span>
          )}
          className="w-full bg-none"
        >
          <DropdownItem
            className="flex gap-4 cursor-pointer"
            onClick={() => {
              navigate("/account/user-profile");
            }}
          >
            <FaRegUser className="size-4" />
            <span>Profile</span>
          </DropdownItem>
          <DropdownItem
            className="flex gap-4 cursor-pointer"
            onClick={() => {
              handleLogout();
            }}
          >
            <MdLogout className="size-4" />
            <span>Logout</span>
          </DropdownItem>
        </Dropdown>
      </div>
    </div>
  );
}
