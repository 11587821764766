import React, { useContext, useState } from "react";

import { Label, TextInput } from "flowbite-react";
import Button from "components/Button";
import { HiCheck, HiExclamation } from "react-icons/hi";

import { jwtDecode } from "jwt-decode";

import banner from "images/mycroft_logo.svg";

import { getToken, setToken as setTokenState } from "utils/util.js";
import { apiFetch } from "utils/api";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "components/AuthContext";

export default function MFALoginPage() {
  const { setToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const [verificationCode, setVerificationCode] = useState("");
  const [recoveryCode, setRecoveryCode] = useState("");
  const [showRecovery, setShowRecovery] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");

  const jwt = getToken();

  const handleSendCode = (e) => {
    e.preventDefault();
    apiFetch("v0/auth/mfa/send", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    });
  };

  const handleRecoverySubmit = (e) => {
    e.preventDefault();
    try {
      apiFetch("v0/auth/mfa/recovery_code", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ magic: recoveryCode }),
      })
        .then((res) => {
          const decoded = jwtDecode(res.jwt);
          if (decoded) {
            setTokenState(res.jwt);
            setToken(res.jwt);
            return res.jwt;
          }
          return null;
        })
        .catch((_) => {
          setToastMessage("Verification failed");
          setToastType("error");
          setShowToast(true);
        });
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };

  const handleAuthSubmit = (e) => {
    e.preventDefault();
    try {
      apiFetch("v0/auth/mfa/authenticate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ otp: verificationCode }),
      })
        .then((res) => {
          const decoded = jwtDecode(res.jwt);
          if (decoded) {
            setTokenState(res.jwt);
            setToken(res.jwt);
            navigate("/home");
            return res.jwt;
          }
          return null;
        })
        .catch((e) => {
          console.log("ERROR", e);
          setToastMessage("Verification failed");
          setToastType("error");
          setShowToast(true);
        });
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };

  return (
    <div className="w-full h-full m-auto flex flex-col">
      {showToast && (
        <div className="fixed top-4 w-[360px]">
          <div
            className="Toast w-[360px] h-16 p-4 bg-white rounded-lg shadow justify-start items-center gap-2.5 inline-flex"
            style={{ marginLeft: "40%" }}
          >
            <div
              className={`IconShapes w-8 h-8 ${
                toastType === "success" ? "bg-[#e1effe]" : "bg-[#fdf2f2]"
              } rounded-lg justify-center items-center flex`}
            >
              {toastType === "success" ? (
                <HiCheck className="w-5 h-5 text-blue-500" />
              ) : (
                <HiExclamation className="w-5 h-5 text-red-500" />
              )}
            </div>
            <div className="Content grow shrink basis-0 h-[15px] justify-start items-center gap-6 flex">
              <div className="Title grow shrink basis-0 text-[#111928] text-xs font-normal leading-[15px]">
                {toastMessage}
              </div>
              <div className="X w-3 h-3 relative flex items-center justify-center">
                <button
                  onClick={() => setShowToast(false)}
                  className="focus:outline-none"
                >
                  ×
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="m-auto">
        <div>
          <img
            alt="A gorgeous logo banner for mycroft.io"
            src={banner}
            className="my-3"
            style={{ maxWidth: "400px", margin: "auto" }}
          />
        </div>
        <div>
          {showRecovery ? (
            <form
              onSubmit={handleRecoverySubmit}
              style={{ maxWidth: "400px", margin: "auto" }}
            >
              <div>
                <Label>Recovery Code</Label>
                <TextInput
                  type="text"
                  name="mfa-recovery-code"
                  placeholder="Enter one of your recovery codes"
                  value={recoveryCode}
                  onChange={(ev) => setRecoveryCode(ev.target.value)}
                />
              </div>

              <Button type="submit" className="my-3">
                Use Recovery Code
              </Button>
            </form>
          ) : (
            <form
              onSubmit={handleAuthSubmit}
              style={{ maxWidth: "400px", margin: "auto" }}
            >
              <div>
                <Label>Verification Code</Label>
                <TextInput
                  type="text"
                  name="verification-code"
                  placeholder="Enter your verification code"
                  value={verificationCode}
                  onChange={(ev) => setVerificationCode(ev.target.value)}
                />
              </div>

              <Button type="submit" className="my-3">
                Login
              </Button>
              <a
                className="ms-3"
                style={{ cursor: "pointer" }}
                href="#top"
                onClick={handleSendCode}
              >
                Send to {jwt?.user?.email}
              </a>
            </form>
          )}
        </div>
        <div className="text-center">
          <a
            className="ms-3"
            style={{ cursor: "pointer" }}
            href="#top"
            onClick={(e) => setShowRecovery(!showRecovery)}
          >
            {showRecovery ? "Enter OTP" : "Use Recovery Code"}
          </a>
        </div>
      </div>
    </div>
  );
}
