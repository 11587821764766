import { Dropdown } from "flowbite-react";

import { FaEllipsisVertical } from "react-icons/fa6";

import Spinner from "components/Spinner";

export default function DotDotDotMenu({ isLoading, items }) {
  return <Dropdown
           label={
             <div>
               <FaEllipsisVertical />
             </div>
           }
           renderTrigger={() => (
             <button className="flex items-center justify-center p-2 text-sm font-medium text-black bg-white rounded-lg hover:text-indigo-700 hover:bg-indigo-100 focus:ring-4 focus:ring-blue-300 transition-colors">
               {isLoading ? <Spinner /> : <FaEllipsisVertical />}
             </button>
           )}
         >
           {items.map((item, ix) => {
             if (item !== undefined) {
               const [icon, label, onClick] = item;
               return <Dropdown.Item
                        key={`dotdotdot-menu-item-${ix}`}
                        onClick={onClick}
                        className="w-full inline-flex items-center space-x-2"
                      >
                        {icon}
                        <span className="text-sm font-medium text-900">
                          {label}
                        </span>
                      </Dropdown.Item>;
             }
             return undefined;
           })}
         </Dropdown>;
}
