import React from "react";
import { FaCircleExclamation } from "react-icons/fa6";
import { IoIosCheckmark } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";

const statusConfig = {
  false: {
    Icon: FaCircleExclamation,
    text: "Incomplete",
    className: "text-red-600 size-4",
  },
  true: {
    Icon: IoIosCheckmark,
    text: "Complete",
    className: "text-green-500 size-4",
  },
  default: {
    Icon: RxCross2,
    text: "None assigned",
    className: "text-grey-100 size-4",
  },
};

export default function PeopleStatusIndicator({
  status = "default",
  showText = false,
}) {
  const { Icon, text, className } =
    statusConfig[status] || statusConfig.default;

  return (
    <div className="inline-flex items-center justify-center space-x-2">
      <Icon className={className} />
      {showText && <span className="text-sm">{text}</span>}
    </div>
  );
}
