import { Accordion } from "flowbite-react";
import NavGroup from "./nav-group";
import { navGroups } from "./nav-group-items";
import { useLocation } from "react-router-dom";

export function Nav() {
  let location = useLocation();

  return (
    <Accordion type="multiple" className="w-full border-none" collapseAll>
      {navGroups.map((navGroup) => (
        <NavGroup
          key={navGroup.group.title}
          currentRoute={location?.pathname}
          {...navGroup}
        />
      ))}
    </Accordion>
  );
}

export default Nav;
