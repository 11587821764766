import React, { useState } from "react";
import { atom, useAtom } from 'jotai';

import { Card, Textarea } from "flowbite-react";
import { HiOutlineChatAlt2 } from "react-icons/hi";

import { apiFetch } from 'utils/api';
import Button from "components/Button";

import Markdown from 'react-markdown';

import ass_logo from "images/mycroft-assistant-logo.png";
import ass_bg from "images/mycroft-assistant-bg.jpg";

const queryHistoryAtom = atom([]);

export default function AiQuery({ className }) {
  const [queryHistory, setQueryHistory] = useAtom(queryHistoryAtom);
  const [query, setQuery] = useState("");
  const [queryWaiting, setQueryWaiting] = useState(false);

  const fetchQuery = () => {
    setQueryWaiting(true);
    apiFetch(`v0/query?prompt=${query}`)
      .then(data => {
        console.log("Response: ", data);
        data.query = query;
        setQueryHistory([data, ...queryHistory]);
      })
      .finally(_ => setQueryWaiting(false));
  };

  // "conic-gradient(from 45deg, rgba(241, 71, 141, 1), rgba(131, 58, 180, 1) 90deg, rgba(58, 123, 213, 1) 180deg, rgba(241, 71, 141, 1) 270deg)"
  // "linear-gradient(to bottom, rgba(241, 71, 141, 1), transparent 50%), linear-gradient(to right, rgba(131, 58, 180, 1), transparent 50%), radial-gradient(circle at 80% 20%, rgba(58, 123, 213, 1), transparent 40%)"

  return <div className={className}>
           <div className="p-6 mycroft-assistant-bg rounded-lg"
                style={{backgroundImage: "url(" + ass_bg + ")",
                /*background: "radial-gradient(circle, rgba(241, 71, 141, 1) 0%, rgba(131, 58, 180, 1) 50%, rgba(58, 123, 213, 1) 100%)"*/ }}>
             <img src={ass_logo} alt="Mycroft barcode assistant logo" className="mb-4 h-8"/>
             <div className="px-6 pt-6 pb-4 rounded-lg"
                  style={{backgroundColor: "rgba(255, 255, 255, 0.85)"}}>
               <Textarea
                 type="text" className="block w-full h-auth mb-3" rows={7}
                 value={query} onChange={ev => setQuery(ev.target.value)} />
               <Button className="" onClick={ev => fetchQuery()} isLoading={queryWaiting} icon={<HiOutlineChatAlt2 />}> Message Assistant</Button>
               {queryHistory.map((q, ix) =>
                 <div>
                   <Card key={`query-result-${ix}`} className="mb-3 w-2/3 bg-purple-700 text-white">
                     <Markdown>{q.result}</Markdown>
                   </Card>
                   <Card key={`query-question-${ix}`} className="mb-3 w-2/3 float-right">
                     <Markdown>{q.query}</Markdown>
                   </Card>
                   <div className="clear-both h-0 w-full"></div>
                 </div>
               )}
             </div>
           </div>
         </div>;
}
