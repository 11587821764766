import { useQuery } from '@tanstack/react-query';

import { Select } from "flowbite-react";
import Spinner from "components/Spinner";

import { getToken } from "utils/util.js";
import { apiFetch, fetchEmployeeList } from "utils/api";

export default function OwnerDropdown({ label, className, onChange }) {
  const jwt = getToken();
  const dropdownId = Math.round(Math.random() * 100000);

  // const employees = useQuery({
  //   queryKey: ["employees"],
  //   queryFn: () => {
  //     console.log("FETCHING FROM employees");
  //     return fetchEmployeeList(jwt.user.org);
  //   }
  // });

  const authUsers = useQuery({
    queryKey: ["authUsers"],
    queryFn: () => {
      console.log("FETCHING FROM OwnerDropdown");
      return apiFetch(`v0/auth/users?org_id=${jwt.user.org}`).then(
        (res) => res.users,
      );
    },
  });

  return (authUsers.isPending ) ? (
    <Spinner className={className} />
  ) : (
    <Select
      label={label || "Owner"}
      className={className}
      onChange={(e) => onChange(e.target.value)}
    >
      <option value={undefined}>Choose {label.toLowerCase()}...</option>
      {authUsers.data.map((user, ix) => (
        <option
          key={`owner-item-${dropdownId}-${ix}`}
          value={user.email}
        >
          {user.email}
        </option>
      ))}
    </Select>
  );
}
