import React, { useState } from "react";

import { Card, Label, TextInput } from "flowbite-react";
import Button from "components/Button";
import Header from "components/Header";
import { Spin } from "utils/util.js";
import { apiFetch } from "utils/api";

const API_URL = "https://api.unified.to";

export async function fetchIntegrations() {
  const url = `${API_URL}/unified/integration/workspace/${process.env.REACT_APP_UNIFIED_WORKSPACE}?summary=1${process.env.REACT_APP_UNIFIED_ENVIRONMENT}`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(String(response.status));
    }

    return response.json();
  } catch (error) {
    console.error("Failed to load data:", error);
    return [];
  }
}

export function unified_get_auth_url(integration, props) {
  let url = `${API_URL}/unified/integration/auth/${process.env.REACT_APP_UNIFIED_WORKSPACE}/${integration.type}?redirect=1`;

  if (props.external_xref) {
    url += `&external_xref=${encodeURIComponent(props.external_xref)}`;
  }
  if (props.state) {
    url += `&state=${encodeURIComponent(props.state)}`;
  }
  if (props.scopes?.length) {
    url += `&scopes=${encodeURIComponent(props.scopes.join(","))}`;
  }
  if (props.environment && props.environment !== "Production") {
    url += `&env=${encodeURIComponent(props.environment)}`;
  }
  if (props.lang) {
    url += `&lang=${props.lang}`;
  }

  url += `&success_redirect=${encodeURIComponent(
    props.success_redirect || "",
  )}`;
  url += `&failure_redirect=${encodeURIComponent(
    props.failure_redirect || "",
  )}`;

  return url;
}

export function UnifiedIntegration({
  data,
  lang,
  scopes,
  state,
  external_xref,
  success_redirect,
  failure_redirect,
  environment,
}) {
  const target_url = unified_get_auth_url(data, {
    lang,
    scopes,
    state,
    external_xref,
    environment,
    success_redirect,
    failure_redirect,
  });
  return (
    <a key={data.type} href={target_url} className="flex justify-start items-center">
      <img
        alt={data.name}
        src={data.logo_url}
        style={{ maxWidth: "50px" }}
        className="h-10 p-1 border rounded border-gray-300 bg-white mr-3"
      />
      <Header lv={5}>{data.name}</Header>
    </a>
  );
}

export function UnifiedConnection({
  data,
  connection,
  handleDisconnect,
  success_redirect,
  state,
  environment,
  scopes,
  extra_param,
}) {
  const [hovering, setHovering] = useState(false);
  const [showInputs, setShowInputs] = useState(false);
  const [extraVal, setExtraVal] = useState("");
  const [showSpin, setShowSpin] = useState(false);

  const styl = {
    width: "20rem",
    cursor: "pointer",
    float: "left",
    minHeight: "7rem",
    backgroundColor: hovering ? "#eee" : "",
  };

  const target_url = unified_get_auth_url(data, {
    scopes,
    state,
    environment,
    success_redirect,
  });

  const handleCancel = () => setShowInputs(false);

  const handleStoreExtras = () => {
    return apiFetch("v0/unified/extra_param", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        param_name: extra_param,
        param_value: extraVal,
      }),
    }).then((_) => {
      setShowInputs(false);
      setShowSpin(true);
      window.location = target_url;
    });
  };

  if (showInputs) {
    return (
      <Card className="mb-4 me-4 p-3" style={styl}>
        <form className="my-3 w-72">
          <div className="mb-4">
            <Label htmlFor="extraParam">{extra_param}</Label>
            <TextInput
              id="extraParam"
              placeholder={extra_param}
              value={extraVal}
              onChange={(ev) => setExtraVal(ev.target.value)}
            />
          </div>
          <div className="grid grid-cols-2 gap-4 my-2">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleStoreExtras}>Connect</Button>
          </div>
        </form>
      </Card>
    );
  }

  if (connection) {
    return (
      <Card
        onMouseOver={(ev) => setHovering(true)}
        onMouseOut={(ev) => setHovering(false)}
        className="mb-4 me-4"
        style={styl}
      >
        <div className="flex justify-start items-center">
          <img
              alt={data.name}
              src={data.logo_url}
              style={{ maxWidth: "50px" }}
              className="h-10 p-1 border rounded border-gray-300 bg-white mr-3"
            />
          <div>
            <Header lv={5}>{data.name}</Header>
            <Button variant="link" tooltip="Disconnect integration" onClick={(ev) => handleDisconnect(connection)}>Disconnect</Button>
          </div>
        </div>
      </Card>
    );
  }

  return (
    <Card
      onMouseOver={(ev) => setHovering(true)}
      onMouseOut={(ev) => setHovering(false)}
      onClick={
        extra_param === undefined || extraVal
          ? undefined
          : (ev) => {
              ev.preventDefault();
              setShowInputs(true);
            }
      }
      className="mb-4 me-4"
      style={styl}
    >
      <div>
        {showSpin ? (
          <Spin />
        ) : (
          <UnifiedIntegration
            data={data}
            state={state}
            success_redirect={success_redirect}
            environment={environment}
            scopes={scopes}
          />
        )}
      </div>
    </Card>
  );
}
