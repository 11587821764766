function MycroftLogo({ fill = "#002B2A", width = "91", height = "21" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 91 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M77.7251 6.75454H79.5437V5.81248C79.5437 5.20515 79.6437 4.64958 79.8473 4.14577C80.0613 3.64196 80.3511 3.21061 80.7238 2.84828C81.093 2.47905 81.5382 2.19609 82.0523 2.0063C82.5803 1.80271 83.1635 1.70264 83.8019 1.70264C84.1849 1.70264 84.53 1.74059 84.844 1.81996C85.1684 1.88898 85.4513 1.98215 85.686 2.10637C85.9206 2.2306 86.1001 2.38243 86.2243 2.56187C86.3589 2.74131 86.4245 2.9311 86.4245 3.1347C86.4245 3.3935 86.3278 3.60745 86.138 3.77309C85.9483 3.94218 85.7067 4.02499 85.4134 4.02499C85.2098 4.02499 85.0338 3.95598 84.8751 3.8214C84.7301 3.68682 84.5058 3.43836 84.2022 3.07948C84.0676 2.92075 83.9261 2.81033 83.7812 2.74131C83.6362 2.6723 83.4844 2.64124 83.3257 2.64124C82.8771 2.64124 82.5216 2.9173 82.2663 3.46597C82.0075 4.00429 81.8798 4.74965 81.8798 5.70551V6.74764H84.5403C84.8095 6.74764 85.0787 6.69243 85.3478 6.57855C85.6273 6.45432 85.8861 6.29904 86.1208 6.1058C86.3554 5.91601 86.5625 5.70206 86.7419 5.46741C86.9317 5.21895 87.0732 4.96705 87.1629 4.7117H88.0222V6.74764H89.958V7.67244H88.0222V12.8382C88.0222 13.5353 88.0946 14.0287 88.2396 14.3186C88.3983 14.6119 88.6536 14.7568 89.0125 14.7568C89.4508 14.7568 89.782 14.5532 90.0063 14.1495L90.3583 14.6361C90.0443 15.4332 89.3024 15.83 88.136 15.83C87.3631 15.83 86.7557 15.6506 86.3175 15.2917C85.8896 14.9328 85.6791 14.429 85.6791 13.7768V7.66899H81.8764V12.8693C81.8764 13.2972 81.9005 13.6422 81.9454 13.9114C82.0006 14.1806 82.0972 14.3945 82.2318 14.5498C82.3767 14.7085 82.57 14.819 82.8046 14.888C83.0393 14.9432 83.3429 14.9777 83.7122 14.988V15.6609H78.0253V14.988C78.3635 14.9777 78.6327 14.9501 78.8328 14.9052C79.0364 14.85 79.1848 14.7534 79.2883 14.6188C79.3884 14.4739 79.4574 14.2772 79.4919 14.0287C79.5264 13.7699 79.5437 13.4283 79.5437 13.0004V7.66554H77.7251V6.74074V6.75454Z"
        fill={fill}
      />
      <path
        d="M72.9492 6.58527C73.6221 6.58527 74.2571 6.7026 74.8506 6.93725C75.4579 7.16155 75.9824 7.47557 76.431 7.87931C76.89 8.28304 77.2489 8.76615 77.5077 9.32517C77.7768 9.87384 77.9114 10.4812 77.9114 11.1437C77.9114 11.8063 77.7768 12.455 77.5077 13.0278C77.2489 13.6007 76.89 14.0941 76.431 14.5082C75.9824 14.9223 75.4545 15.2501 74.8506 15.4848C74.2571 15.7194 73.6221 15.8367 72.9492 15.8367C72.2763 15.8367 71.6587 15.7229 71.0651 15.4986C70.4716 15.2639 69.9471 14.9361 69.4985 14.522C69.0499 14.1079 68.6979 13.6145 68.4391 13.0416C68.1803 12.4585 68.0526 11.8235 68.0526 11.1403C68.0526 10.457 68.1803 9.86694 68.4391 9.30447C68.6979 8.742 69.0499 8.26234 69.4985 7.8586C69.9471 7.45486 70.4681 7.14085 71.0651 6.91655C71.6587 6.69225 72.2867 6.57837 72.9492 6.57837V6.58527ZM72.9492 7.56184C72.6111 7.56184 72.3039 7.65156 72.0244 7.831C71.7449 8.00008 71.5034 8.24164 71.2998 8.55566C71.11 8.86967 70.9581 9.25271 70.8443 9.7013C70.7442 10.1396 70.6924 10.6261 70.6924 11.1644C70.6924 11.7027 70.7442 12.2307 70.8443 12.6793C70.9581 13.1279 71.1065 13.5109 71.2998 13.825C71.5034 14.139 71.738 14.3874 72.0072 14.5669C72.2867 14.736 72.6007 14.8188 72.9492 14.8188C73.2978 14.8188 73.6118 14.736 73.8913 14.5669C74.1846 14.3874 74.4296 14.139 74.6332 13.825C74.8368 13.5006 74.9921 13.1141 75.106 12.6621C75.2198 12.2135 75.275 11.7131 75.275 11.1644C75.275 10.6158 75.2198 10.1361 75.106 9.7013C74.9921 9.26306 74.8368 8.88692 74.6332 8.57291C74.4296 8.24853 74.1846 8.00008 73.8913 7.831C73.6118 7.65156 73.2978 7.56184 72.9492 7.56184Z"
        fill={fill}
      />
      <path
        d="M64.4807 13.066C64.4807 13.5042 64.4979 13.8527 64.5324 14.1081C64.5773 14.3566 64.6601 14.5463 64.7843 14.6809C64.9086 14.8155 65.088 14.9052 65.3226 14.9501C65.5573 14.9846 65.8713 15.0018 66.2647 15.0018V15.6747H60.6434V15.0018C60.9816 14.9777 61.2507 14.9466 61.4509 14.9018C61.6545 14.8569 61.8029 14.7672 61.9064 14.6326C62.0065 14.498 62.0686 14.3082 62.0927 14.0598C62.1272 13.801 62.1445 13.4594 62.1445 13.0315V10.9783C62.1445 10.2812 62.1272 9.72563 62.0927 9.31154C62.0582 8.89745 61.9858 8.57653 61.8753 8.35223C61.7615 8.12794 61.5993 7.98301 61.3888 7.91399C61.1852 7.83462 60.916 7.79666 60.5813 7.79666V7.20659L63.9458 6.58545L64.2149 8.52132H64.284C64.5876 7.92779 64.9845 7.45504 65.4779 7.10651C65.9714 6.75799 66.4924 6.58545 67.0446 6.58545C67.4725 6.58545 67.8175 6.69243 68.0867 6.90637C68.3559 7.12032 68.4904 7.38948 68.4904 7.71385C68.4904 8.07273 68.3835 8.37639 68.1695 8.62139C67.9659 8.86985 67.6864 8.99063 67.3275 8.99063C67.1584 8.99063 67.0066 8.95612 66.872 8.89056C66.7375 8.82154 66.6132 8.74907 66.5028 8.67316C66.4027 8.58344 66.2958 8.50407 66.1819 8.4385C66.0818 8.35914 65.9748 8.32118 65.861 8.32118C65.647 8.32118 65.4469 8.46266 65.2536 8.74217C65.0638 9.02168 64.9051 9.39782 64.7809 9.87057C64.6911 10.2087 64.6187 10.5883 64.5635 11.0162C64.5083 11.4303 64.4807 11.8479 64.4807 12.2619V13.0694V13.066Z"
        fill={fill}
      />
      <path
        d="M59.8295 14.2772C59.4016 14.7499 58.8702 15.1123 58.2318 15.3711C57.6038 15.6299 56.9205 15.7576 56.1786 15.7576C55.4954 15.7576 54.8708 15.6506 54.3117 15.4366C53.7493 15.2123 53.2696 14.9052 52.8659 14.5118C52.4621 14.1184 52.1481 13.6491 51.9238 13.097C51.7099 12.5346 51.6029 11.9203 51.6029 11.2474C51.6029 10.5745 51.7202 9.91888 51.9549 9.34605C52.1895 8.77323 52.5208 8.28667 52.9487 7.88293C53.3766 7.46884 53.8839 7.14792 54.4808 6.92362C55.0744 6.69932 55.7335 6.58545 56.4512 6.58545C56.955 6.58545 57.4209 6.63721 57.8488 6.73728C58.287 6.83736 58.6562 6.97884 58.9599 7.15828C59.2739 7.33772 59.5224 7.55166 59.7018 7.79666C59.8813 8.03131 59.971 8.29012 59.971 8.56963C59.971 8.894 59.8744 9.15281 59.6846 9.3426C59.5051 9.5324 59.2567 9.62902 58.9427 9.62902C58.6839 9.62902 58.4561 9.56 58.2525 9.42542C58.0627 9.29084 57.8695 9.07344 57.6797 8.76978C57.5106 8.50062 57.3657 8.28667 57.2414 8.13139C57.1276 7.97265 57.024 7.85533 56.9205 7.77941C56.8204 7.68969 56.7169 7.63448 56.6169 7.61032C56.5168 7.57582 56.3926 7.55856 56.2476 7.55856C55.5954 7.55856 55.0916 7.86223 54.7327 8.46611C54.3739 9.07344 54.1944 9.91888 54.1944 11.0059C54.1944 12.1722 54.4084 13.0694 54.8328 13.6975C55.2711 14.3255 55.8922 14.6395 56.6997 14.6395C57.1586 14.6395 57.621 14.5567 58.08 14.3876C58.5389 14.2082 58.9772 13.9563 59.3913 13.6319L59.8295 14.2703V14.2772Z"
        fill={fill}
      />
      <path
        d="M46.4198 18.3177C46.2956 18.5661 46.1403 18.7939 45.9471 19.0078C45.7676 19.2218 45.5606 19.4012 45.3259 19.5461C45.1016 19.7049 44.867 19.8256 44.6185 19.9154C44.3701 20.0051 44.1251 20.0499 43.8766 20.0499C43.5074 20.0499 43.2106 19.9602 42.9863 19.7808C42.762 19.6013 42.6482 19.3598 42.6482 19.0561C42.6482 18.7766 42.7448 18.5558 42.9346 18.4005C43.1382 18.2418 43.4556 18.1486 43.8939 18.1141C44.8911 18.0347 45.5364 17.7207 45.8298 17.172L46.2853 16.2955L43.3073 8.92471C43.1727 8.60034 43.0553 8.34154 42.9553 8.15174C42.8656 7.96195 42.7655 7.81357 42.6516 7.7135C42.5377 7.59962 42.4101 7.52715 42.2651 7.4961C42.1202 7.46159 41.9408 7.44089 41.7268 7.42708V6.75419H46.827V7.42708C46.3888 7.42708 46.0817 7.47194 45.9022 7.56166C45.7331 7.64103 45.6503 7.79286 45.6503 8.01716C45.6503 8.10688 45.6607 8.21386 45.6848 8.33808C45.7193 8.45196 45.7573 8.56928 45.8021 8.69006L47.5172 13.6039H47.5862L49.0321 10.3223C49.177 9.97374 49.3012 9.68733 49.4013 9.46303C49.5014 9.22838 49.5738 9.03169 49.6187 8.87295C49.6739 8.70386 49.7153 8.56928 49.736 8.46921C49.7602 8.36914 49.7705 8.26562 49.7705 8.16554C49.7705 7.87223 49.6877 7.67899 49.5186 7.57546C49.3599 7.47539 49.0355 7.42363 48.5421 7.42363V6.75073H52.5967V7.42363C52.3724 7.42363 52.1826 7.45814 52.0239 7.5237C51.8651 7.57892 51.7029 7.70315 51.5373 7.89294C51.3786 8.08273 51.1991 8.36914 50.999 8.75217C50.8092 9.1214 50.5676 9.61486 50.2743 10.2325L46.4198 18.3108V18.3177Z"
        fill={fill}
      />
      <path
        d="M33.6243 12.5274L37.5133 2.7169H42.4271V3.3898C42.0579 3.3898 41.7542 3.42775 41.5196 3.50712C41.2953 3.57614 41.1227 3.70381 40.9985 3.8936C40.8743 4.0834 40.7915 4.34911 40.7466 4.68383C40.7121 5.0082 40.6949 5.42574 40.6949 5.92955V12.4584C40.6949 13.0209 40.719 13.4626 40.7639 13.7869C40.8087 14.1113 40.8984 14.3632 41.033 14.5426C41.1676 14.7221 41.3574 14.8394 41.6059 14.8946C41.8647 14.9498 42.1994 14.9843 42.6169 14.9947V15.6676H36.2882V14.9947C36.7023 14.9843 37.0336 14.9498 37.2821 14.8946C37.5409 14.8256 37.7376 14.7083 37.8721 14.5426C38.0171 14.3736 38.1137 14.1389 38.1585 13.8352C38.2034 13.5212 38.2276 13.1002 38.2276 12.5723V4.69763H38.1447L33.6174 15.8401H32.3716L27.6924 4.69763H27.6234V12.4549C27.6234 13.0174 27.6475 13.4591 27.6924 13.7835C27.7373 14.1078 27.827 14.3597 27.9616 14.5392C28.0961 14.7186 28.2859 14.836 28.5344 14.8912C28.7932 14.9464 29.1279 14.9809 29.5455 14.9912V15.6641H24.3624V14.9912C24.7765 14.9809 25.1147 14.9464 25.3735 14.8912C25.6323 14.8222 25.829 14.7048 25.9636 14.5392C26.1085 14.3701 26.2051 14.1354 26.25 13.8318C26.2948 13.5178 26.319 13.0968 26.319 12.5688V5.80187C26.319 5.32912 26.2948 4.93919 26.25 4.62517C26.2051 4.31115 26.1223 4.06269 25.9981 3.88325C25.8739 3.69346 25.6944 3.56233 25.4598 3.49677C25.2355 3.4174 24.9318 3.37944 24.5522 3.37944V2.70654H29.6524L33.5725 12.517H33.6415L33.6243 12.5274Z"
        fill={fill}
      />
      <path
        d="M0.00345018 4.10379C0.0103517 4.20386 0.0207037 4.27633 0.0207037 4.27633H0.034507L1.79784 13.4622H1.78749C1.78749 13.4622 1.80129 13.5451 1.83925 13.6693C1.83925 13.6693 1.83925 13.6727 1.83925 13.6762L0 4.10034L0.00345018 4.10379Z"
        fill={fill}
      />
      <path
        d="M10.8876 18.0312L16.112 15.1705C16.112 15.1705 16.1224 15.167 16.1258 15.1636C16.1569 15.1463 16.1707 15.1291 16.1983 15.1118L10.822 18.0519C10.8427 18.0415 10.8634 18.0415 10.8841 18.0312H10.8876Z"
        fill={fill}
      />
      <path
        d="M3.08476 15.157C3.08476 15.157 3.09857 15.1708 3.10892 15.1777V15.1708L8.32646 18.0314C8.32646 18.0314 8.35062 18.0452 8.38858 18.0659C8.40583 18.0763 8.45414 18.0935 8.47829 18.1039L3.01575 15.1086C3.0399 15.1224 3.05716 15.1397 3.08131 15.157H3.08476Z"
        fill={fill}
      />
      <path
        d="M17.3888 13.6627C17.3888 13.6627 17.3957 13.6489 17.3957 13.6455C17.4268 13.5316 17.4371 13.4626 17.4371 13.4626H17.4302L19.1763 4.27669H19.1832C19.1832 4.27669 19.197 4.21803 19.2039 4.13176C19.2039 4.12831 19.2039 4.1214 19.2039 4.1145L17.3888 13.6627Z"
        fill={fill}
      />
      <path
        d="M18.6152 3.22065C18.6152 3.22065 18.6083 3.21375 18.6014 3.21029H18.5911L11.3652 0.349621V0.342718C11.3445 0.332366 11.3203 0.328916 11.2996 0.322014C9.87793 -0.219754 8.68052 0.0459525 8.15601 0.228842C7.96966 0.290956 7.86269 0.346167 7.86269 0.346167L0.623005 3.21374H0.605752C0.605752 3.21374 0.595399 3.2241 0.585047 3.22755C0.0536304 3.45185 -0.00848198 3.86594 0.00532105 4.10059L1.84457 13.6764C1.94119 14.0008 2.23106 14.6323 3.02128 15.1085L8.48383 18.1038C8.8151 18.2625 9.75716 18.6041 10.8269 18.052L16.2032 15.112C17.0072 14.6323 17.2971 13.987 17.3868 13.6626L19.2019 4.11439C19.2157 3.87974 19.1639 3.4484 18.6118 3.22065H18.6152ZM4.933 13.6454V13.6385C3.73559 13.1209 3.61826 11.7578 3.61826 11.7578H3.61136L2.60374 6.49886C2.54163 5.96055 2.53818 4.79074 3.63552 4.48362L3.87707 4.41461V4.42151C3.87707 4.42151 5.26082 3.80383 5.53688 5.88118L6.27534 14.2217L4.933 13.6419V13.6454ZM11.8449 5.01504L10.9649 14.9705C9.61567 15.495 8.40446 15.0533 8.28023 15.005L7.39684 5.01504V4.92532C7.39684 4.92532 7.2243 3.79692 8.31819 3.231C8.45967 3.17234 9.50525 2.7962 10.9235 3.231C12.0588 3.65199 11.8518 4.97018 11.8518 4.97018L11.8414 5.01849L11.8449 5.01504ZM15.6131 11.7578H15.6062C15.6062 11.7578 15.4889 13.114 14.2984 13.635L12.9698 14.2079L13.6979 5.99506C13.8705 4.39046 14.6572 4.28348 15.092 4.34905L15.6614 4.50778C16.5586 4.79419 16.6828 5.67068 16.6621 6.25041L15.6165 11.7578H15.6131Z"
        fill={fill}
      />
    </svg>
  );
}

export default MycroftLogo;
