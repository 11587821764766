import React, { useState } from "react";

import QRCode from "react-qr-code";

import { Card, Label, TextInput } from "flowbite-react";

import Button from "components/Button";

import { setToken, getToken } from "utils/util.js";
import { apiFetch } from "utils/api";

function MFAChange({ user, setUser }) {
  const [url, setUrl] = useState(null);
  const [recoveryTokens, setRecoveryTokens] = useState([]);
  const [mfaEnabled, setMfaEnabled] = useState(user.mfa_enabled);

  const updateToken = (res) => {
    if (res.jwt) {
      setToken(res.jwt);
      const jwt = getToken();
      setUser(jwt.user);
    }
  };

  const updateUser = () =>
    apiFetch("v0/auth/renew", { method: "POST" }).then(updateToken);

  const enableMFA = () => {
    console.log("POSTING...");
    return apiFetch("v0/auth/mfa/enable", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      console.log("RES:", res);
      setUrl(res.url);
      setRecoveryTokens(res.recovery_tokens);
      setMfaEnabled(true);
      updateToken(res);
      return res;
    });
  };

  const disableMFA = () =>
    apiFetch("v0/auth/mfa/disable", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      setUrl(null);
      setRecoveryTokens([]);
      setMfaEnabled(false);
      return updateUser();
    });

  return (
    <div className="border rounded bg-white">
      <div className="p-4 px-6">
        <h3>Security</h3>
      </div>
      <div className="border !border-x-0 !border-b-0 border-t-1 border-gray-200 h-1 w-full"></div>
      <div className="p-4 px-6">
        {mfaEnabled ? (
          <>
            <div>
              {url && <QRCode className="my-3" value={url} />}
              {recoveryTokens.length ? (
                <Card className="my-2 p-3 text-center">
                  <h3>Record these codes somewhere safe!</h3>
                  <p>
                    <i>
                      (We don't know them and can't give them to you again.)
                    </i>
                  </p>
                  <ul style={{ listStyleType: "none" }}>
                    {recoveryTokens.map((tok, ix) => (
                      <li key={ix}>
                        <pre>{tok}</pre>
                      </li>
                    ))}
                  </ul>
                </Card>
              ) : (
                ""
              )}
            </div>
            <div>
              <Button onClick={(e) => disableMFA()}>Disable MFA</Button>
            </div>
          </>
        ) : (
          <div className="flex flex-col space-y-2">
            <div className="flex items-center gap-2">
              <span>Two-factor authentication (2FA)</span>
              <div></div>
            </div>
            <div>
              <Button onClick={(e) => enableMFA()}>Enable MFA</Button>
            </div>
          </div>
        )}
        <div className="my-4 border !border-x-0 !border-b-0 border-t-1 border-gray-200 h-1 w-full"></div>
        <ChangePasswordForm />
      </div>
    </div>
  );
}

const ChangePasswordForm = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const handleChangePassword = (e) => {
    const newVal = e.target.value;
    setPassword(newVal);
    setPasswordsMatch(newVal === confirmPassword);
  };

  const handleChangeConfirmPassword = (e) => {
    const newVal = e.target.value;
    setConfirmPassword(newVal);
    setPasswordsMatch(password === newVal);
  };

  const handleClickChangePassword = () => {
    if (passwordsMatch) {
      apiFetch("v0/auth/change_password", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ password: password }),
      }).then((_) => {
        setPassword("");
        setConfirmPassword("");
      });
    }
  };

  return (
    <>
      <h3 className="my-5">Change Password</h3>
      <div>
        <div>
          <Label>New Password:</Label>
          <div className="flex gap-2">
            <TextInput
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={handleChangePassword}
            />
            <Button
              variant="light"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? "👁" : "-"}
            </Button>
          </div>
        </div>

        <div>
          <Label>Confirm New Password:</Label>
          <div className="flex gap-2">
            <TextInput
              type={showConfirmPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={handleChangeConfirmPassword}
              isInvalid={!passwordsMatch}
            />
            <Button
              variant="light"
              onClick={(e) => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? "👁" : "-"}
            </Button>
          </div>
        </div>

        <Button
          variant="primary"
          className="mt-2"
          onClick={handleClickChangePassword}
          disabled={!password.length || !passwordsMatch}
        >
          Change Password
        </Button>
      </div>
    </>
  );
};

function ChangeForms({ user, setUser }) {
  const [name, setName] = useState(user.name || "");
  const [nameEnabled, setNameEnabled] = useState(false);
  // const [picture, setPicture] = useState(user.picture || '');

  const handleChangeName = (e) => {
    setNameEnabled(true);
    setName(e.target.value);
  };

  const handleClickUpdateUser = () => {
    console.log("UPDATING USER", name);
    apiFetch("v0/auth/update_user", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ name: name }),
    })
      .then((_) => apiFetch("v0/auth/renew", { method: "POST" }))
      .then((res) => {
        if (res.jwt) {
          setNameEnabled(false);
          setToken(res.jwt);
          const jwt = getToken();
          setUser(jwt.user);
          setName(jwt.user.name);
        }
      });
  };

  return (
    <div className="border rounded bg-white">
      <div className="p-4">
        <h3>Your Info</h3>
      </div>
      <div className="border !border-x-0 !border-b-0 border-t-1 border-gray-200 h-1 w-full"></div>
      <div className="p-4">
        <div className="flex flex-col space-y-2">
          <Label>First Name</Label>
          <TextInput
            className="max-w-[175px]"
            value={name}
            onChange={handleChangeName}
          />
        </div>

        <Button
          variant="primary"
          className="mt-2"
          onClick={handleClickUpdateUser}
          disabled={!nameEnabled}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export default function UserProfilePage() {
  const [user, setUser] = useState(getToken().user);

  return (
    <>
      <h1 className="font-semibold text-xl mb-6">User Profile</h1>

      <div className="flex flex-col space-y-4">
        <ChangeForms user={user} setUser={setUser} />

        <MFAChange user={user} setUser={setUser} />
      </div>
    </>
  );
}
