import React, { useState } from "react";

import { Textarea } from "flowbite-react";

import { FaDownload, FaEdit } from "react-icons/fa";
import { FaArrowRotateLeft, FaCircleCheck } from "react-icons/fa6";

import Button from "components/Button";

import downloadjs from "downloadjs";
import Markdown from "react-markdown";

export default function MarkdownEditor({
  contents,
  fetchSave,
  fetchGenerate,
  fetchDownload,
  downloadName,
  extraButtons,
}) {
  const [editing, setEditing] = useState(false);
  const [text, setText] = useState(contents);
  const [dirty, setDirty] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [generating, setGenerating] = useState(false);

  const handleDownload = () => {
    setDownloading(true);
    fetchDownload(text)
      .then((res) => res.blob())
      .then((blob) => downloadjs(blob, downloadName || "document.pdf"))
      .finally((_) => setDownloading(false));
  };

  const handleSave = () => {
    setSaving(true);
    fetchSave(text)
      .then((_) => setDirty(false))
      .finally((_) => setSaving(false));
  };

  const handleGenerate = () => {
    setGenerating(true);
    fetchGenerate(text)
      .then((revised) => setText(revised))
      .finally((_) => setGenerating(false));
  };

  return (
    <div className="flex-1 w-full">
      <div className="flex w-full">
        <div className="flex flex-row space-x-4">
          {extraButtons ? extraButtons : ""}
          {fetchDownload && (
            <Button
              icon={<FaDownload />}
              variant="light"
              className="px-6"
              isLoading={downloading}
              onClick={handleDownload}
            >
              Download PDF
            </Button>
          )}

          {fetchGenerate && (
            <Button
              icon={<FaArrowRotateLeft />}
              variant="light"
              className="px-6"
              isLoading={generating}
              onClick={handleGenerate}
              tooltip="Recreate the existing details using the latest context within your organization."
            >
              Generate
            </Button>
          )}
        </div>

        <div className="flex flex-row space-x-4 ml-auto">
          {fetchSave && (
            <Button
              icon={<FaCircleCheck />}
              variant="dark"
              className="px-6"
              disabled={!dirty}
              isLoading={saving}
              onClick={handleSave}
            >
              Save Changes
            </Button>
          )}
          {editing ? (
            <Button
              icon={<FaEdit />}
              variant="dark"
              className="px-6"
              onClick={(e) => setEditing(false)}
            >
              Preview
            </Button>
          ) : (
            <Button
              icon={<FaEdit />}
              variant="dark"
              className="px-6"
              onClick={(e) => setEditing(true)}
            >
              Edit
            </Button>
          )}
        </div>
      </div>
      <div className="w-full p-5">
        {editing ? (
          <Textarea
            type="text"
            className="block w-full h-auto"
            rows={20}
            value={text}
            onChange={(ev) => {
              setDirty(true);
              return setText(ev.target.value);
            }}
          />
        ) : (
          <Markdown className="prose w-full max-w-full">{text}</Markdown>
        )}
      </div>
    </div>
  );
}
