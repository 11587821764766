export const CustomTabTheme = {
  tablist: {
    variant: {
      underline:
        "-mb-px flex-wrap border-b border-gray-200 dark:border-gray-700",
    },
    tabitem: {
      base: "flex items-center justify-center rounded-t-lg p-4 text-sm font-medium first:ml-0 focus:outline-none focus:ring-3 focus:ring-indigo-200 disabled:cursor-not-allowed disabled:text-gray-400",
      variant: {
        underline: {
          active: {
            on: "border-b-2 border-indigo-600 text-indigo-600",
            off: "hover:text-indigo-700 hover:border-indigo-600 border-b-2 border-transparent",
          },
        },
      },
    },
  },
};
