import { FaBell, FaBuilding, FaFileImage, FaHome, FaPlug } from "react-icons/fa";

export const navGroups = [
  {
    group: {
      title: "Home",
      icon: FaHome,
      link: "/home",
    },
  },
  {
    group: {
      title: "Compliance",
      icon: FaFileImage,
    },
    links: [
      {
        title: "Controls",
        link: "/compliance/controls",
      },
      {
        title: "Policies",
        link: "/compliance/policies",
      },
      {
        title: "Tests",
        link: "/compliance/evidence",
      },
    ],
  },
  {
    group: {
      title: "Risk",
      icon: FaBell,
    },
    links: [
      {
        title: "Cloud Scan",
        link: "/risk/cloud-scanner",
      },
      {
        title: "App Scanner",
        link: "/risk/app-scanner",
      },
      {
        title: "Vendors",
        link: "/risk/vendors",
      },
    ],
  },
  {
    group: {
      title: "Organization",
      icon: FaBuilding,
    },
    links: [
      {
        title: "People",
        link: "/organization/people",
      },
      {
        title: "Computers",
        link: "/organization/computers",
      },
      {
        title: "Password Manager",
        link: "/organization/password-manager",
      },
      {
        title: "Settings",
        link: "/organization/settings",
      },
    ],
  },
  {
    group: {
      title: "Integrations",
      icon: FaPlug,
      link: "/integrations",
    },
  },
];

export const groupToLinks = navGroups.reduce((acc, group) => {
  acc[group.group.title] = group.links?.map((x) => x.link) ?? [];
  return acc;
}, {});
