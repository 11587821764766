export default function Header({ lv, className, children, onClick }) {
  const cls = (base) =>
    [className || "", onClick ? "cursor-pointer" : "", "scroll-m-20", base]
      .filter((a) => a)
      .join(" ");

  switch (lv) {
    case 2:
      return (
        <h2
          onClick={onClick}
          className={cls("text-lg font-medium leading-tight tracking-tight")}
        >
          {children}
        </h2>
      );

    case 3:
      return (
        <h3
          onClick={onClick}
          className={cls("text-base font-semibold leading-tight tracking-tight")}
        >
          {children}
        </h3>
      );

    case 4:
      return (
        <h4
          onClick={onClick}
          className={cls("text-xs font-semibold leading-tight tracking-tight")}
        >
          {children}
        </h4>
      );

    case 5:
      return (
        <h5
          onClick={onClick}
          className={cls("text-base font-semibold leading-tight tracking-tight")}
        >
          {children}
        </h5>
      );

    case 6:
      return (
        <p
          onClick={onClick}
          className={cls("font-medium leading-none")}
        >
          {children}
        </p>
      );
    default:
      return (
        <h1
          onClick={onClick}
          className={cls("text-lg font-semibold leading-none tracking-tight lg:text-lg")}
        >
          {children}
        </h1>
      );
  }
}
