export default function Link({ href, text, className, children }) {
  return (
    <a
      href={href}
      className={`text-blue-500 underline hover:no-underline hover:text-blue-700 ${className}`}
    >
      {children || text}
    </a>
  );
}
