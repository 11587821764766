import React, { useState } from "react";
import { Breadcrumb } from "flowbite-react";
import Header from "components/Header";
import { useSearchParams, Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { fetchEmployeeDetails } from "utils/api";
import PersonDrawer from "pages/PeoplePage/PersonDrawer";
import DotDotDotMenu from "components/DotDotDotMenu";
import LabelRow from "components/LabelRow";

import { getToken, prettyDate } from "utils/util";
import PeopleStatusIndicator from "pages/PeoplePage/PeopleStatusIndicator";

import { IoShieldCheckmark } from "react-icons/io5";
import { BiEdit, BiSolidShieldX } from "react-icons/bi";
import { HiMiniTrash } from "react-icons/hi2";
import { LuTrash2 } from "react-icons/lu";
import { LiaUserEditSolid } from "react-icons/lia";

function EmployeeDropdown({ employee, isLoading, handleUpdate }) {
  return (
    <DotDotDotMenu
      isLoading={isLoading}
      items={[
        [
          <LiaUserEditSolid />,
          "Update details",
          () => {
            handleUpdate(employee);
          },
        ],
        [<BiEdit />, "Manager computers", () => {}],
        [<LuTrash2 />, "Deactivate person", () => {}],
      ]}
    />
  );
}

export default function DetailedPerson() {
  const user = getToken().user;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  let employee = {};

  const handleUpdate = (emp) => {
    setIsDrawerOpen(true);
    setSelectedEmployee(emp);
  };

  const { isPending, isError, data } = useQuery({
    queryKey: ["personInfo", email],
    queryFn: () => fetchEmployeeDetails(email),
    retry: 1,
    onError: (error) => {
      console.error("Failed to fetch employees:", error);
    },
    enabled: !!email,
  });

  if (data) {
    employee = data;
  }

  const complianceCheck = (employee) => {
    if (employee?.deleted) {
      return (
        <div className="inline-flex items-center justify-center bg-[#f1f2f5] text-grey-800 font-medium rounded-lg px-4 py-1 space-x-2">
          <HiMiniTrash /> <span className="status-text">Deactivated</span>
        </div>
      );
    } else if (employee?.policies_sign_off && employee?.background_check) {
      return (
        <div className="inline-flex items-center justify-center bg-[#DEF7EC] text-[#057A55] rounded-lg px-4 py-1 space-x-2">
          <IoShieldCheckmark /> <span className="status-text">Compliant</span>
        </div>
      );
    } else {
      return (
        <div className="inline-flex items-center justify-center bg-[#FDE8E8] text-[#C81E1E] rounded-lg px-4 py-1 space-x-2">
          <BiSolidShieldX className="w-6 h-6" />
          <span className="status-text">Not compliant</span>
        </div>
      );
    }
  };

  return (
    <div>
      <Breadcrumb aria-label="breadcrumb" className="mb-6">
        <Breadcrumb.Item className=" underline underline-offset-2">
          <Link to={"/organization/people"} className="breadcrumb-text">
            People
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span className="breadcrumb-text">{employee?.name}</span>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="max-w border border-1 border-gray-200 bg-white rounded-lg shadow">
        <div className="flex flex-row py-3 px-6">
          <div className="flex items-center">
            <Header lv={6}>Details</Header>
          </div>
          <div className=" ml-auto">
            <EmployeeDropdown
              employee={employee}
              isLoading={isPending}
              handleUpdate={handleUpdate}
            />
            <PersonDrawer
              org_id={user.org}
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              selectedEmployee={selectedEmployee}
              detailedView={true}
            />
          </div>
        </div>
        <div className="w-full border-b border-gray-200" />
        <div className="px-6 py-4">
          <LabelRow
            className="mb-4"
            labels={[
              "Name",
              "Work email address",
              "Start date",
              "Manager",
              "Title",
            ]}
            values={[
              employee?.name || <span className="text-gray-400">—</span>,
              employee?.email || <span className="text-gray-400">—</span>,
              prettyDate(employee?.created) || (
                <span className="text-gray-400">—</span>
              ),
              employee?.manager || <span className="text-gray-400">—</span>,
              employee?.job_title || <span className="text-gray-400">—</span>,
            ]}
          />

          <div>
            <label className="text-xxs uppercase font-semibold text-gray-500 mb-1">
              Job description
            </label>
            <p>{employee?.job_description || "-"}</p>
          </div>
          <hr className="mt-6 mb-4" />
          <div className="mb-4">
            <label className="text-xxs uppercase font-semibold text-gray-500 mb-1">
              Status
            </label>
            <div>{complianceCheck(employee)}</div>
          </div>
          <LabelRow
            className="mb-4"
            labels={["Background check", "Policies", "Computers", "Training"]}
            values={[
              <PeopleStatusIndicator
                status={employee?.background_check}
                showText
              />,
              <PeopleStatusIndicator
                status={employee?.policies_sign_off}
                showText
              />,
              <PeopleStatusIndicator showText={true} />,
              <PeopleStatusIndicator showText={true} />,
            ]}
          />
        </div>
      </div>
    </div>
  );
}
