import React, { useState } from "react";

import Header from "components/Header";
import Button from "components/Button";

export function Palette({ open, children, width, title, onClickClose }) {
  return (
    <div
      className={`fixed inset-y-0 right-0 w-64 bg-white transform transition-transform duration-300 ease-in-out ${
        open ? "translate-x-0 shadow-drawer" : "translate-x-full"
      } flex flex-col`}
      style={width ? { width: width } : {}}
    >
      {(title || onClickClose) && (
        <div className="flex items-center justify-between pl-6 pr-4 py-2 border-b border-gray-200">
          <Header lv={3}>{title || "Details"} </Header>

          <Button onClick={onClickClose} variant="closeButton">
            ✕
          </Button>
        </div>
      )}
      <div className="flex-1 overflow-y-auto p-6">{children}</div>
    </div>
  );
}

export function OffCanvas({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
      >
        Open
      </button>

      <Palette open={isOpen}>
        <button
          onClick={() => setIsOpen(false)}
          className="mb-4 px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600"
        >
          Close
        </button>
        {children}
      </Palette>
    </>
  );
}
