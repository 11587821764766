import React, { useState, useContext } from "react";

import { jwtDecode } from "jwt-decode";

import banner from "images/mycroft_logo.svg";
import { HiCheck, HiExclamation } from "react-icons/hi";
import { Spin, setToken as setTokenState } from "utils/util.js";
import { apiAnonPost } from "utils/api";
import { AuthContext } from "components/AuthContext";
import { useNavigate } from "react-router-dom";

const uriPieceToParams = (str) =>
  str.split("&").reduce((memo, el) => {
    const s = el.split("=");
    if (s.length > 1) {
      memo[s[0]] = decodeURIComponent(s[1]);
    }
    return memo;
  }, {});

function UnifiedProvider({ auth }) {
  const url = `https://api.unified.to/unified/integration/login/${
    process.env.REACT_APP_UNIFIED_WORKSPACE
  }/${auth.type}?redirect=true&success_redirect=${encodeURIComponent(
    process.env.REACT_APP_FRONTEND,
  )}`;
  return (
    <a
      href={url}
      className="w-full flex flex-row gap-x-6 my-1 bg-[#F8F9FA] px-5 p-3 border border-gray-300 rounded-lg hover:bg-[#DFE0E1]"
    >
      <img
        src={auth.logo_url}
        alt={`Logo icon for ${auth.name}`}
        style={{ maxHeight: "25px" }}
      />
      Sign in with {auth.name}
    </a>
  );
}

export default function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [triedTokens, setTriedTokens] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");

  const [integrations, setIntegrations] = useState(undefined);

  const navigate = useNavigate();
  const { setToken } = useContext(AuthContext);

  if (sessionStorage.getItem("mycroft-jwt")) {
    try {
      const _token = sessionStorage.getItem("mycroft-jwt");
      const decodedToken = jwtDecode(_token);
      const currentTime = Date.now() / 1000;

      console.log("decodedToken", decodedToken);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        navigate("/home");
      } else {
        setToken(null);
        setTokenState(null);
        sessionStorage.removeItem("mycroft-jwt");
      }
    } catch (error) {
      setToken(null);
      setTokenState(null);
      sessionStorage.removeItem("mycroft-jwt");
    }
  }

  if (integrations === undefined) {
    fetch(
      `https://api.unified.to/unified/integration/workspace/${process.env.REACT_APP_UNIFIED_WORKSPACE}?categories=auth&active=true&summary=true&env=${process.env.REACT_APP_UNIFIED_ENVIRONMENT}`,
    )
      .then((dat) => dat.json())
      .then((dat) => {
        setIntegrations(dat);
      });
  }

  const params = Object.assign(
    {},
    uriPieceToParams(window.location.search.substr(1)),
    uriPieceToParams(window.location.hash.substr(1)),
  );

  const fetchAuth = (url, params, error_message) => {
    try {
      return apiAnonPost(url, params)
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          }
          if (res.status === 400) {
            setToastMessage(error_message);
            setToastType("error");
            setShowToast(true);
            return Promise.reject(new Error(error_message));
          }
          setToastMessage(
            "A server error prevented us from authenticating your account.",
          );
          setToastType("error");
          setShowToast(true);
          return Promise.reject(new Error("other error"));
        })
        .then((res) => {
          const decoded = jwtDecode(res.jwt);
          if (decoded) {
            if (decoded?.user?.mfa_enabled) {
              setTokenState(res.jwt);
              setToken(res.jwt);
              navigate("/mfa");
            } else {
              setTokenState(res.jwt);
              setToken(res.jwt);
              navigate("/home");
            }
            return res.jwt;
          }
          return null;
        })
        .catch((err) => {
          console.log(err);
          setToastMessage(error_message);
          setToastType("error");
          setShowToast(true);
        });
    } catch (error) {
      console.error("Error logging in:", error);
      return Promise.reject(new Error("login errored completely"));
    }
  };

  if (
    params.jwt &&
    !triedTokens.includes(params.access_token) &&
    !showSpinner
  ) {
    setShowSpinner(true);
    fetchAuth(
      "v0/auth/oauth/authenticate",
      { jwt: params.jwt },
      "Invalid token",
    )
      .then((_) =>
        window.location.assign(
          window.location.origin + window.location.pathname,
        ),
      )
      .finally((_) => setShowSpinner(false));
    setTriedTokens(triedTokens.concat([params.access_token]));
  }

  if (
    params.email &&
    params.magic &&
    !triedTokens.includes(params.magic) &&
    !showSpinner
  ) {
    setShowSpinner(true);
    fetchAuth(
      "v0/auth/forgot_password/authenticate",
      { email: params.email, magic: params.magic },
      "Invalid token",
    )
      .then((_) =>
        window.location.assign(
          window.location.origin + window.location.pathname,
        ),
      )
      .finally((_) => setShowSpinner(false));
    setTriedTokens(triedTokens.concat([params.magic]));
  }

  const handleForgotPassword = (e) => {
    e.preventDefault();
    if (!/\S+@\S+/.test(email)) {
      return undefined;
    }
    setToastMessage(
      "A password reset email has been sent to this email address.",
    );
    setToastType("success");
    setShowToast(true);
    return apiAnonPost("v0/auth/forgot_password", { email: email }).then(
      (res) => console.log(res),
    );
  };

  const handleAuthSubmit = (e) => {
    e.preventDefault();

    return fetchAuth(
      "v0/auth",
      { email: email, password: password },
      "Incorrect password",
    );
  };

  return (
    <div className="w-screen h-screen bg-[#1C4F3C] flex items-center justify-center">
      {showToast && (
        <div className="fixed top-4 w-[360px]">
          <div className="Toast w-[360px] h-16 p-4 bg-white rounded-lg shadow justify-start items-center gap-2.5 inline-flex">
            <div
              className={`IconShapes w-8 h-8 ${
                toastType === "success" ? "bg-[#e1effe]" : "bg-[#fdf2f2]"
              } rounded-lg justify-center items-center flex`}
            >
              {toastType === "success" ? (
                <HiCheck className="w-5 h-5 text-blue-500" />
              ) : (
                <HiExclamation className="w-5 h-5 text-red-500" />
              )}
            </div>
            <div className="Content grow shrink basis-0 h-[15px] justify-start items-center gap-6 flex">
              <div className="Title grow shrink basis-0 text-[#111928] text-xs font-normal leading-[15px]">
                {toastMessage}
              </div>
              <div className="X w-3 h-3 relative flex items-center justify-center">
                <button
                  onClick={() => setShowToast(false)}
                  className="focus:outline-none"
                >
                  ×
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="block w-[390px] py-6 border bg-white rounded-lg flex flex-col justify-center px-4 gap-y-3 ">
        <div className="justify-center flex items-center pb-4 pt-3">
          <img
            className=""
            alt="A gorgeous logo banner for mycroft.io"
            src={banner}
          />
        </div>
        <div>
          <form className="w-full flex flex-col gap-y-3">
            <div>
              <label className="font-inter text-[#111928]">Email</label>
              <input
                type="email"
                id="email"
                placeholder="Your email address"
                className="block w-full px-3 py-2 border border-gray-300 rounded-lg placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-gray-50"
                onChange={(ev) => setEmail(ev.target.value)}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    handleAuthSubmit(ev);
                  }
                }}
              />
            </div>
            <div>
              <label className="font-inter text-[#111928]">Password</label>
              <div className="flex flex-col mb-3">
                <input
                  type="password"
                  id="password"
                  placeholder="Password"
                  className="block w-full px-3 py-2 border border-gray-300 rounded-lg placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-gray-50"
                  onChange={(ev) => setPassword(ev.target.value)}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      handleAuthSubmit(ev);
                    }
                  }}
                />
                <div className="ml-auto group">
                  <button
                    onClick={handleForgotPassword}
                    disabled={!email}
                    className={`text-inter text-sm ${
                      email === "" ? `text-black` : `text-[#5145CD]`
                    } `}
                  >
                    Forgot password?
                  </button>
                  <div
                    className={`form-tooltip  ${
                      email === "" ? `group-hover:scale-100` : ``
                    }`}
                  >
                    <div className="absolute left-1/2 border-b-8 border-b-black border-r-8 border-r-transparent border-l-8 border-l-transparent top-[-8px]" />
                    Please provide an email address.
                  </div>
                </div>
              </div>
            </div>
            {showSpinner && <Spin />}

            <button
              variant="primary"
              type="submit"
              className="bg-[#5145CD] text-white px-3 py-2 mb-2 rounded-lg"
              disabled={showSpinner}
              onClick={handleAuthSubmit}
            >
              Login
            </button>
          </form>
        </div>
        <div className="Separator h-6 w-full flex items-center relative">
          <div className="Line1 flex-grow h-px bg-gray-300"></div>
          <div className="Group18 relative flex items-center justify-center px-2 bg-white">
            <span className="text-gray-300 text-xs font-bold">OR</span>
          </div>
          <div className="Line1 flex-grow h-px bg-gray-300"></div>
        </div>
        <div className="pb-4 flex flex-col">
          {integrations &&
            integrations.map((integ, ix) => (
              <UnifiedProvider key={ix} auth={integ} />
            ))}
        </div>
      </div>
    </div>
  );
}
