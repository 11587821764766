import React, { useState } from "react";

import { Accordion, Badge, Card } from "flowbite-react";
import Button from "components/Button";
import Table from "components/Table";

import { Spin } from "utils/util.js";
import { apiFetch } from "utils/api";

function Collapsible({ header, body, className}) {
  const [show, setShow] = useState(false);
  return <div className={className}>
           <div className="cursor-pointer hover:bg-purple-200 p-1 px-2 pt-2" onClick={e => setShow(!show)}>
             { header }
           </div>
           { show && body }
         </div>;
}

const caseToColor = (level) => {
  if (level === "danger") {
    return "bg-red-700 text-white";
  }
  if (level === "warning") {
    return "bg-yellow-300";
  }
  return "bg-green-600 text-white";
};

function CloudScanSection ({ counts, ix, k, v}) {
  return <Collapsible
           header={<div className="flex flex-wrap">
                     <div className="flex w-24">
                       {["danger", "warning", "success"].map(lv =>
                         (counts[lv]
                          ? <Badge className={`me-1 ${caseToColor(lv)}`} >{counts[lv]}</Badge>
                          : ""))}
                     </div>
                     {k}
                   </div>}
           body={<div>
                   {v.findings && v.findings !== {} && Object.entries(v.findings).map(([finding_name, finding], f_ix) => {
                     const level = finding.flagged_items === 0 ? "success" : finding.level;
                     const color = level === "danger" ? "failure" : level;
                     const header = <div className="flex flex-wrap">
                                      <Badge className={`me-1 ${color === 'success' ? 'ms-6' : 'ms-4'} ${caseToColor(level)}`}>&nbsp;</Badge> {finding_name}
                                    </div>;
                     const body = <div>
                                    <ul className="ms-12 border border-black min-w-96 px-5 my-3 rounded-md">
                                      {finding.rationale
                                       && <li className="px-2 border-b-2 border-black">{finding.rationale}</li>}
                                      {finding.remediation
                                       && <li className="px-2 border-b-2 border-black">{finding.remediation}</li>}
                                      {finding.references
                                       && <li className="px-2 border-b-2 border-black">
                                            <ul>
                                              {finding.references.map(uri => {
                                                const url = new URL(uri);
                                                const path = url.pathname.split("/").filter(e => e);
                                                const name = path[path.length - 1].split(".")[0];
                                                return <li><a href={uri}>{name}</a></li>;
                                              })}
                                            </ul>
                                          </li>}
                                      {finding.path
                                       && <li className="px-2 border-b-2 border-black">{finding.path}</li>}
                                      {finding.items
                                       && <li className="px-2">
                                            <div>Flagged: {finding.flagged_items}</div>
                                            <ul style={{listStyleType: "disc"}}>
                                              {finding.items.map(item => <li>{item}</li>)}
                                            </ul>
                                          </li>}
                                    </ul>
                                  </div>;
                     return level === "success" ? header : (<Collapsible header={header} body={body}/>);
                   })}
	         </div>}/>;
}

function CloudScanResult ({ result }) {
  return <ul>
           {result && Object.entries(result.services || {}).map(([k, v], ix) => {
             const counts = {success: 0, warning: 0, danger: 0};
             if (v.findings && v.findings !== {}) {
               Object.entries(v.findings).forEach(([_name, finding]) => {
                 if (finding.flagged_items === 0) {
                   counts.success += 1;
                 } else {
                   counts[finding.level] += 1;
                 }});
             }

             if (counts.success === 0 && counts.warning === 0 && counts.danger === 0) {
               return "";
             }

	     return <li eventKey={ix} key={`${k}-${ix}`}>
	              <CloudScanSection counts={counts} ix={ix} k={k} v={v} />
	            </li>;})}
         </ul>;
}

function RepoSummary(repo, ix) {
  // const expandable =
  //       (repo.issues && repo.issues.length) || repo.pull_requests.length;

  return (
    <li key={`repo-${ix}`} className="flex flex-col space-y-4">
      <div className="w-full">
        <a href={repo.url} className="mx-2" target="BLANK">
          {repo.name}
        </a>
      </div>
      <div>
        {(repo.issues && repo.issues.length)
         ? <Accordion collapseAll className="w-full">
             <Accordion.Panel>
               <Accordion.Title>Issues</Accordion.Title>
               <Accordion.Content>
                 <Table
                   headers={["", "State", "Labels", "Assignees", "Description"]}
                   rows={repo.issues.map(issue => [
                     <a href={issue.url}>{issue.title}</a>,
                     issue.state, issue.labels.join(", "),
                     issue.assignees.join(", "),
                     <p>{issue.body}</p>
                   ])}
                 />
               </Accordion.Content>
             </Accordion.Panel>
           </Accordion>
         : ""}
        {(repo.pull_requests && repo.pull_requests.length)
         ? <Accordion collapseAll className="w-full">
             <Accordion.Panel>
               <Accordion.Title>Pull Requests</Accordion.Title>
               <Accordion.Content>
                 <Table
                   headers={["", "Author", "State", "Merged"]}
                   rows={repo.pull_requests.map(pr => [
                     <a href={pr.url}>{pr.url}</a>,
                     pr.author, pr.state, JSON.stringify(pr.merged)
                   ])}
                 />
               </Accordion.Content>
             </Accordion.Panel>
           </Accordion>
         : ""}
      </div>
    </li>
  );
}

export default function ProcessPage() {
  const [infoFetchRes, setInfoFetchRes] = useState(null);

  const fetchInfo = () =>
    apiFetch("v0/info").then((res) => {
      console.log(res);
      return setInfoFetchRes(res);
    });

  if (infoFetchRes === null) {
    fetchInfo();
  }

  const startScan = (endpoint) =>
    apiFetch(`v0/${endpoint}`, { method: "POST" })
      .then(console.log)
      .then(fetchInfo);
  const progress = (infoFetchRes || {}).in_progress || [];

  return (
    <div className="flex flex-col space-y-4">
      <h2>Cloud Scans</h2>
      {progress.includes("scoutsuite_gcp") ||
      progress.includes("scoutsuite_aws") ? (
        <Spin />
      ) : (
        <Button onClick={(ev) => startScan("cloud-scan")}>Scan</Button>
      )}
      {infoFetchRes &&
       infoFetchRes.cloudscan &&
       Object.entries(infoFetchRes.cloudscan)
       .filter(([key, value]) => !["github", "bitbucket"].includes(key))
       .map(([key, v], ix) => {
         const value = v.scan_result;
         if (value === undefined || value === null || value === {}) {
           return "";
         }
         return (
           <span key={ix}>
             <h4 className="mt-3">{key}</h4>
             <CloudScanResult result={value} />
           </span>
         );
       })}
      <h2 className="mt-3">Repo Scans</h2>
      {progress.includes("github_scan") ||
      progress.includes("bitbucket_scan") ? (
        <Spin />
      ) : (
        <Button onClick={(ev) => startScan("repo-scan")}>Scan</Button>
      )}
      {infoFetchRes &&
        infoFetchRes.cloudscan &&
        Object.entries(infoFetchRes.cloudscan)
          .filter(([key, value]) => ["github", "bitbucket"].includes(key))
          .map(([key, value], ix) => {
            if (value === undefined || value === null || value === {}) {
              return "";
            }
            if (value.status && value.status === "error") {
              return (
                <span key={ix}>
                  <h4 className="mt-3">{key}</h4>
                  <Card bg="danger" text="white" style={{ maxWidth: "18rem" }}>
                    <h5>{value.status}</h5>
                    <p>{value.error}</p>
                  </Card>
                </span>
              );
            }
            return (
              <>
                <h4 className="mt-3">{key}</h4>
                <ul>
                  {value.scan_result.map((repo, ix) => RepoSummary(repo, ix))}
                </ul>
              </>
            );
          })}
    </div>
  );
}
