import { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

import { Spinner } from "flowbite-react";
import { apiFetch } from "utils/api";

export const allEqual = (arr) => {
  const fst = arr[0];
  return arr.every(el => el === fst);
};

export const capitalize = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const memoized = (fn, by) => {
  const cache = new Map();

  return function (...args) {
    const key = by ? by(...args) : JSON.stringify(args);

    if (!cache.has(key)) {
      const result = fn.apply(this, args);
      cache.set(key, result);
    }
    return cache.get(key);
  };
};

export const prettyDate = (dateString) =>
  new Date(dateString).toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

export const clearToken = () => {
  sessionStorage.removeItem("mycroft-jwt");
};

export const setToken = (jwt) => {
  sessionStorage.setItem("mycroft-jwt", jwt);
};

export const getRawToken = () => {
  const tok = sessionStorage.getItem("mycroft-jwt");
  if (!tok) {
    return undefined;
  }
  return tok;
};

export const getToken = () => {
  try {
    const tok = getRawToken();
    if (!tok) {
      return undefined;
    }
    return jwtDecode(tok);
  } catch (err) {
    return undefined;
  }
};

export const mustChangePassword = () => {
  const token = getToken();
  return token && token.user && token.user.require_password_change;
};

export const mustEnterOTP = () => {
  const token = getToken();
  return token && token.user && token.user.mfa_enabled && !token.mfa_verified;
};

export const isAuthenticated = () => {
  const token = getToken();
  if (token === undefined) {
    return false;
  }
  if (Date.now() > token.exp * 1000) {
    return false;
  }
  return true;
};

export function Spin() {
  return <Spinner color="info" />;
}

export const localeCompare = (kFn) => {
  const f = kFn || ((i) => i);
  return (a, b) =>
    f(a).localeCompare &&
    f(a).localeCompare(f(b), undefined, { numeric: true, sensitivity: "base" });
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export const getOrgInfo = async () => {
  const jwt = await getToken();
  const orgLogo = await apiFetch("v0/info").then((res) => {
    return res.extras.logo;
  });

  const orgName = jwt.user.org;

  return {
    name: orgName,
    logo: `data:image/png;base64,${orgLogo}`,
  };
};

export function lastUrlSegment(urlString) {
  try {
    const url = new URL(urlString);

    const path = url.pathname.replace(/^\/|\/$/g, '');

    const segments = path.split('/');
    return segments[segments.length - 1] || '';
  } catch (error) {
    console.error('Invalid URL:', error);
  }
  return undefined;
};
