import React, { useState, useEffect } from "react";
import { Drawer, TextInput, Label, Datepicker, Textarea } from "flowbite-react";
import Button from "components/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addEmployee } from "utils/api";
import Spinner from "components/Spinner";

export default function PersonDrawer({
  org_id = 0,
  isDrawerOpen = false,
  setIsDrawerOpen,
  selectedEmployee = {},
  detailedView = false,
  deselectEmployee = () => {},
}) {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    startDate: new Date(),
    background_check: false,
    policies_sign_off: false,
    manager: "",
    job_title: "",
    jobDescription: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (Object.hasOwn(selectedEmployee, "name")) {
      let first_name = "";
      let last_name = "";
      const name =
        selectedEmployee?.name !== null
          ? selectedEmployee?.name.trim().split(/\s+/)
          : "";
      if (name.length > 1) {
        first_name = name[0] || "";
        last_name = name.slice(1).join(" ") || "";
      }

      setFormData((prevData) => ({
        ...prevData,
        firstName: first_name,
        lastName: last_name,
        email: selectedEmployee?.email,
        job_title: selectedEmployee?.job_title,
        jobDescription: selectedEmployee?.job_description,
      }));
      setIsEditing(true);
    }
  }, [isDrawerOpen]);

  const addEmployeeMutation = useMutation({
    mutationFn: (newEmployee) => {
      return addEmployee("v0/employee/add", newEmployee);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["employeeInfo"] });
      handleClose();
    },
    onError: (error) => {
      console.error("Error adding employee:", error);
    },
  });

  const updateEmployeeMutation = useMutation({
    mutationFn: (existingEmployee) => {
      return addEmployee("v0/employee/update", existingEmployee);
    },
    onSuccess: () => {
      if (detailedView) {
        queryClient.invalidateQueries({ queryKey: ["personInfo"] });
      } else {
        queryClient.invalidateQueries({ queryKey: ["employeeInfo"] });
      }
      handleClose();
    },
    onError: (error) => {
      console.error("Error updating employee:", error);
    },
  });

  const formatDate = (date) => {
    const options = { month: "short", day: "numeric", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const handleClose = () => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      startDate: new Date(),
      background_check: false,
      policies_sign_off: false,
      manager: "",
      job_title: "",
      jobDescription: "",
    });
    deselectEmployee();
    setIsEditing(false);
    setIsDrawerOpen(false);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      startDate: date,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const employeeObject = {
      email: formData.email,
      org_id: org_id,
      name: `${formData.firstName} ${formData.lastName}`,
      job_title: formData.job_title,
      job_description: formData.jobDescription,
      background_check: formData.background_check,
      policies_sign_off: formData.policies_sign_off,
      picture: [],
      assets: [],
    };
    if (isEditing) updateEmployeeMutation.mutate(employeeObject);
    else addEmployeeMutation.mutate(employeeObject);
  };

  return (
    <>
      <Drawer
        open={isDrawerOpen}
        onClose={handleClose}
        position="right"
        className="w-1/3"
      >
        <Drawer.Header
          title={isEditing ? "Update Person" : "Add A Person"}
          className="uppercase"
          titleIcon={() => <></>}
        />
        <Drawer.Items>
          <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div className="flex flex-row space-x-6">
              <div className="flex flex-col w-1/2">
                <div className="mb-2 block">
                  <Label htmlFor="firstName" value="First name" />
                </div>
                <TextInput
                  id="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder=""
                  required
                />
              </div>
              <div className="flex flex-col ml-auto w-1/2">
                <div className="mb-2 block">
                  <Label htmlFor="lastName" value="Last name" />
                </div>
                <TextInput
                  id="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  placeholder=""
                  required
                />
              </div>
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="email" value="Email address" />
              </div>
              <TextInput
                id="email"
                value={formData.email}
                onChange={handleChange}
                type="email"
                required
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="startDate" value="Start date" />
              </div>
              <Datepicker
                id="startDate"
                value={formatDate(formData.startDate)}
                onSelectedDateChanged={handleDateChange}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="manager" value="Manager" />
              </div>
              <TextInput
                id="manager"
                value={formData.manager}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="title" value="Title" />
              </div>
              <TextInput
                id="job_title"
                value={formData.job_title}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="jobDescription" value="Job description" />
              </div>
              <Textarea
                id="jobDescription"
                value={formData.jobDescription}
                onChange={handleChange}
                className="p-2"
                required
                rows={4}
              />
            </div>
            <div className="flex flex-row space-x-4 fixed bottom-0 mb-4">
              <Button type="submit" variant={"indigo"} className="bg-[#5145CD]">
                {addEmployeeMutation.isPending ||
                updateEmployeeMutation.isPending ? (
                  <Spinner />
                ) : isEditing ? (
                  "Update Details"
                ) : (
                  "Add person"
                )}
              </Button>
              <Button
                variant={"ghostOutline"}
                type="button"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </form>
        </Drawer.Items>
      </Drawer>
    </>
  );
}
