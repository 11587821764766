import React, { useState } from "react";
import { useQuery } from '@tanstack/react-query';

import { Select, Modal } from "flowbite-react";
import Spinner from "components/Spinner";
import Button from "components/Button";
import Header from "components/Header";
import OwnerDropdown from "components/OwnerDropdown";

import { getToken } from "utils/util.js";
import { apiFetch } from "utils/api";

export default function ChangeOwnerModal({ show, isLoading, onClose, onChange, title, description, currentOwner }) {
  const [newOwner, setNewOwner] = useState(undefined);

  const modalId = Math.round(Math.random() * 100000);

  return <Modal show={show} onClose={onClose}>
           <Modal.Header>Change Owner</Modal.Header>
           <Modal.Body>
             <Header lv={3}>{title}</Header>
             {description && <p className="mb-3">{description}</p>}
             <div className="flex flex-row justify-center items-center text-center">
               <div className="w-1/2 h-14">
                 <p className="item-heading w-full mb-3">Current Owner</p>
                 {currentOwner || "--"}
               </div>
               <div className="w-1/2 h-14">
                 <p className="item-heading w-full mb-3">New Owner</p>
                 <OwnerDropdown label="New Owner" onChange={setNewOwner}/>
               </div>
             </div>
           </Modal.Body>
           <Modal.Footer>
             <Button
               onClick={e => onChange(newOwner)}
               disabled={!newOwner}
               isLoading={isLoading}
             >
               Change owner
             </Button>
             <Button onClick={onClose}>Cancel</Button>
           </Modal.Footer>
         </Modal>;
}
