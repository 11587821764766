import React, { useState } from "react";

import {
  Label,
  FileInput,
  TextInput,
  ListGroup,
  Accordion,
} from "flowbite-react";
import Button from "components/Button";
import { getToken } from "utils/util.js";
import { apiFetch } from "utils/api";

function ManageExistingOrg({ org }) {
  const [usersFetchRes, setUsersFetchRes] = useState(null);

  const [newUserName, setNewUserName] = useState("");
  const [newUserPassword, setNewUserPassword] = useState("");
  const [newUserScopes, setNewUserScopes] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const updateUserList = () =>
    apiFetch(`v0/auth/users?org_name=${org.name}`).then(setUsersFetchRes);
  if (usersFetchRes === null) {
    updateUserList();
  }

  const handleAddUser = () => {
    console.log("ADDING USER", newUserName, newUserPassword);
    apiFetch("v0/auth/register", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        org_id: org.id,
        email: newUserName,
        password: newUserPassword,
        scopes: newUserScopes.split(",").map((s) => s.trim()),
      }),
    })
      .then(updateUserList)
      .then((_) => {
        setNewUserName("");
        setNewUserPassword("");
        setNewUserScopes("");
      });
  };

  function SingleUser({ user }) {
    return (
      <ul>
        <li>{user.email}</li>
        <li>{JSON.stringify(user.scopes)}</li>
      </ul>
    );
  }

  return (
    <>
      <ListGroup>
        <ListGroup.Item>
          {org.extras && org.extras.logo ? (
            <img
              style={{ maxWidth: "50px" }}
              alt={`Org logo for ${org.name}`}
              src={`data:image/png;base64,${org.extras.logo}`}
            />
          ) : (
            "(No logo uploaded)"
          )}
        </ListGroup.Item>
        <ListGroup.Item>
          <a href={org.website}>Website</a>
        </ListGroup.Item>
        <ListGroup.Item>
          <ListGroup>
            {usersFetchRes &&
              usersFetchRes.users &&
              usersFetchRes.users.map((user, ix) => (
                <ListGroup.Item key={`user-${org.name}-${ix}`}>
                  <SingleUser user={user} />
                </ListGroup.Item>
              ))}
            <ListGroup.Item>
              <TextInput
                placeholder="Username"
                value={newUserName}
                onChange={(e) => {
                  setNewUserName(e.target.value);
                }}
              />
              <TextInput
                placeholder="comma,separated,scopes"
                value={newUserScopes}
                onChange={(e) => {
                  setNewUserScopes(e.target.value);
                }}
              />
              <div className="mb-2">
                <TextInput
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={newUserPassword}
                  onChange={(e) => {
                    setNewUserPassword(e.target.value);
                  }}
                />
                <Button
                  variant="light"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? "👁" : "-"}
                </Button>
              </div>
              <Button onClick={handleAddUser}>Add User</Button>
            </ListGroup.Item>
          </ListGroup>
        </ListGroup.Item>
      </ListGroup>
    </>
  );
}

export default function OrgManagementPage() {
  const [infoFetchRes, setInfoFetchRes] = useState(null);
  const [orgListFetchRes, setOrgListFetchRes] = useState(null);

  const jwt = getToken();

  const shouldShowOrgInterface =
    jwt.user.scopes.includes("godlike") || jwt.user.scopes.includes("add_orgs");

  const updateOrgList = () => apiFetch("v0/org").then(setOrgListFetchRes);
  if (orgListFetchRes === null && shouldShowOrgInterface) {
    updateOrgList();
  }

  const updateInfo = () => apiFetch("v0/info").then(setInfoFetchRes);
  if (infoFetchRes === null) {
    updateInfo();
  }

  function ManageCurrentOrgAccessTokens() {
    const [key, setKey] = useState("");
    const [value, setValue] = useState("");

    const handleSaveNewToken = () => {
      const tokMap = {};
      tokMap[key] = value;

      return apiFetch("v0/org/creds", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ add: tokMap }),
      })
        .then((_) => apiFetch("v0/info"))
        .then((res) => setInfoFetchRes(res));
    };

    const handleRemoveToken = (tokenName) =>
      apiFetch("v0/org/creds", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ remove: [tokenName] }),
      }).then(updateInfo);

    return (
      <>
        <h4>Access Tokens</h4>
        {(infoFetchRes || {}).access_token_names ? (
          <>
            <ListGroup className="my-3">
              {infoFetchRes.access_token_names.sort().map((name) => (
                <ListGroup.Item key={name}>
                  <Button
                    variant="danger"
                    onClick={() => handleRemoveToken(name)}
                    className="mx-2"
                  >
                    -
                  </Button>
                  {name}: *****
                </ListGroup.Item>
              ))}
            </ListGroup>
          </>
        ) : (
          ""
        )}
        <form className="flex flex-wrap">
          <div className="w-1/6 me-5">
            <Label>Key</Label>
            <TextInput
              placeholder="Key"
              value={key}
              onChange={(e) => setKey(e.target.value)}
            />
          </div>
          <div className="w-1/6">
            <Label>Value</Label>
            <TextInput
              placeholder="Value"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          </div>
        </form>
        <Button onClick={handleSaveNewToken} className="my-3">
          Add
        </Button>
      </>
    );
  }

  function AddNewOrg() {
    const [name, setName] = useState("");
    const [website, setWebsite] = useState("");
    const [logo, setLogo] = useState(null);

    const handleNameChange = (e) => {
      setName(e.target.value);
    };

    const handleWebsiteChange = (e) => {
      setWebsite(e.target.value);
    };

    const handleLogoChange = (e) => {
      setLogo(e.target.files[0]);
    };

    const handleSubmit = () => {
      const formData = new FormData();
      formData.append("org_name", name);
      formData.append("website", website);
      formData.append("logo", logo);

      apiFetch("v0/org/register", {
        method: "POST",
        body: formData,
      }).then(updateOrgList);
    };

    return (
      <>
        <h3>Register New Org</h3>
        <div>
          <div>
            <Label>Name</Label>
            <TextInput value={name} onChange={handleNameChange} />
          </div>

          <div>
            <Label>Website</Label>
            <TextInput value={website} onChange={handleWebsiteChange} />
          </div>

          <div>
            <Label>Logo Image</Label>
            <FileInput onChange={handleLogoChange} />
          </div>

          <Button onClick={handleSubmit} className="my-3">
            Register
          </Button>
        </div>
      </>
    );
  }

  function ExistingOrgs() {
    return (
      <>
        <h3>Existing Orgs</h3>
        <Accordion collapseAll>
          {orgListFetchRes ? (
            orgListFetchRes.orgs.map((org, ix) => (
              <Accordion.Panel
                key={`org-${ix}`}
                title={`${org.name} - ${org.website}`}
              >
                <Accordion.Title>{org.name}</Accordion.Title>
                <Accordion.Content>
                  <ManageExistingOrg org={org} />
                </Accordion.Content>
              </Accordion.Panel>
            ))
          ) : (
            <Accordion.Panel />
          )}
        </Accordion>
      </>
    );
  }

  function ManageCurrentWebsite() {
    const [website, setWebsite] = useState(
      (infoFetchRes && infoFetchRes.website) || "",
    );

    const handleSubmit = () => {
      console.log("SENDING", website);
      apiFetch("v0/org/website", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ website: website }),
      }).then(updateInfo);
    };

    return (
      <>
        <h4>Website</h4>
        <TextInput
          value={website}
          onChange={(e) => setWebsite(e.target.value)}
        />
        <Button onClick={handleSubmit} className="my-3">
          Save
        </Button>
      </>
    );
  }

  function ManageCurrentOrgLogo() {
    const [logo, setLogo] = useState(null);

    const handleLogoChange = (e) => {
      setLogo(e.target.files[0]);
    };

    const handleSubmit = () => {
      const formData = new FormData();
      formData.append("logo", logo);

      apiFetch("v0/org/logo", {
        method: "POST",
        body: formData,
      }).then(updateInfo);
    };
    return (
      <>
        <h4>Logo</h4>
        <div>
          {infoFetchRes && infoFetchRes.extras && infoFetchRes.extras.logo ? (
            <img
              style={{ maxWidth: "200px" }}
              src={`data:image/png;base64,${infoFetchRes.extras.logo}`}
              alt={`Org logo for ${jwt.user.org}`}
            />
          ) : (
            ""
          )}
          <FileInput onChange={handleLogoChange} />
          <Button onClick={handleSubmit} className="my-3">
            Save
          </Button>
        </div>
      </>
    );
  }

  return (
    <div>
      <h3>Manage Org - {jwt.user.org} </h3>
      <ManageCurrentOrgLogo />
      <ManageCurrentWebsite />
      <ManageCurrentOrgAccessTokens />
      {jwt.user.scopes.includes("godlike") ||
      jwt.user.scopes.includes("add_orgs") ? (
        <>
          <hr />
          <AddNewOrg />
          <hr />
          <ExistingOrgs />
        </>
      ) : (
        ""
      )}
    </div>
  );
}
