/* <Button label="Default" /> */
/* <Button variant="alternative" label="Alternative" /> */
/* <Button variant="dark"> */
/*   <span>LALALA</span> */
/* </Button> */
/* <Button variant="light" /> */
/* <Button variant="green" /> */
/* <Button variant="red" /> */
/* <Button variant="yellow" /> */
/* <Button variant="purple" /> */

import { Tooltip } from "flowbite-react";

import Spinner from "components/Spinner";

const SIZING =
  "py-2.5 px-5 me-2 mb-2 text-sm font-medium rounded-md text-center transition-colors";

const DEFAULT_VARIANT =
  "text-white bg-indigo-700 hover:bg-indigo-800 focus:outline-none focus:ring-4 focus:ring-indigo-300";
const DISABLED_DEFAULT = "text-white bg-indigo-700";

const VARIANTS = {
  alternative:
    "text-gray-900 focus:outline-none bg-white border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700",
  dark: "text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700",
  light:
    "text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700",
  green:
    "text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800",
  red: "text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900",
  yellow:
    "text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-4 focus:ring-yellow-300 dark:focus:ring-yellow-900",
  purple:
    "text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900",
  ghost:
    "text-gray-900 bg-white rounded-none focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:bg-gray-800",
  ghostOutline:
    "text-gray-900 bg-white outline outline-1 outline-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:bg-gray-800",
  indigo:
    "text-gray-900 bg-indigo-700 text-white focus:outline-none  focus:ring-4 focus:ring-gray-100 dark:bg-gray-800",
  link: "p-0 m-0 text-sm font-semibold text-indigo-600 hover:text-indigo-800 hover:underline focus:ring-4 focus:ring-indigo-200 transition-colors",
  closeButton:
    "py-2 px-3 text-gray-700 hover:bg-indigo-100 hover:text-indigo-700 focus:outline-none focus:ring-3 focus:ring-indigo-200 focus:bg-indigo-100 text-sm font-medium rounded-md text-center transition-colors",
};

const SIZING_EXCLUDED_VARIANTS = ["link", "closeButton"];

const DISABLED_VARIANTS = Object.keys(VARIANTS).reduce((acc, key) => {
  acc[key] = VARIANTS[key]
    .replace(/hover:[^ ]+ /g, "")
    .replace(/focus:[^ ]+ /g, "");
  return acc;
}, {});

export default function Button({
  type = "button",
  variant,
  icon,
  label,
  children,
  onClick,
  isLoading,
  disabled,
  className,
  tooltip,
}) {
  const btn = (
    <button
      type={type}
      className={[
        variant && SIZING_EXCLUDED_VARIANTS.includes(variant) ? "" : SIZING,
        className ? className : "",
        icon ? "whitespace-nowrap" : "",
        isLoading ? "cursor-wait" : "",
        disabled || isLoading
          ? (DISABLED_VARIANTS[variant] || DISABLED_DEFAULT) +
            " opacity-75 cursor-not-allowed"
          : VARIANTS[variant] || DEFAULT_VARIANT,
      ].join(" ")}
      onClick={(e) => !disabled && !isLoading && onClick && onClick(e)}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="inline-flex items-center justify-start space-x-2 ">
          {icon ? <div className="me-2">{icon}</div> : ""}
          {children || label || ""}
        </div>
      )}
    </button>
  );
  if (tooltip === undefined) {
    return btn;
  }
  return (
    <Tooltip
      content={tooltip}
      className="max-w-xs text-center"
      placement="bottom"
    >
      {btn}
    </Tooltip>
  );
}
