import { memo, useState, useEffect } from "react";
import { cn } from "utils/cn";
import { groupToLinks } from "./nav-group-items";
import { Accordion } from "flowbite-react";
import { Link } from "react-router-dom";

import { FaAngleDown, FaAngleUp } from "react-icons/fa6";

const NavGroup = ({ group, links, currentRoute }) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectedRouteStyles = "bg-custom-light-green";

  useEffect(() => {
    if (links && links.some((link) => link.link === currentRoute)) {
      setIsOpen(true);
    }
  }, [currentRoute, links]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  if (!links || links.length === 0) {
    return (
      <Accordion.Panel key={group.title} className="text-white cursor-pointer">
        <Link
          to={group?.link}
          className="font-medium border-none cursor-pointer text-white transition-colors"
        >
          <div
            className={cn(
              "hover:bg-custom-light-green py-3 cursor-pointer px-6 flex gap-2 items-center text-white border-l-2 border-transparent transition-colors",
              currentRoute === group?.link &&
                `${selectedRouteStyles} border-custom-yellow`,
            )}
          >
            <group.icon className="size-4 min-w-4" />
            <p className="text-13px">{group.title}</p>
          </div>
        </Link>
      </Accordion.Panel>
    );
  }

  return (
    <Accordion.Panel key={group.title}>
      <Accordion.Title
        onClick={handleToggle}
        className={cn(
          "hover:bg-custom-light-green px-6 py-3 focus:ring-0 border-l-2 !border-t-0 !border-transparent transition-colors",
          groupToLinks[group.title].includes(currentRoute) &&
            "!border-custom-yellow rounded-l-none",
        )}
      >
        <div className="flex items-center text-white justify-between transition-all">
          <div className="flex gap-2 items-center w-full w-20 mr-4">
            <group.icon className="size-4 min-w-4" />
            <div className="text-13px min-w-[146px]">{group.title}</div>
          </div>
          {isOpen ? (
            <FaAngleUp className="size-2.5" />
          ) : (
            <FaAngleDown className="size-2.5" />
          )}
        </div>
      </Accordion.Title>
      {links?.map(({ title, link }) => (
        <Accordion.Content
          key={title}
          className={cn(
            "mx-6 px-6 py-2 my-1 flex items-center text-13px hover:bg-custom-light-green rounded-md transition-colors",
            currentRoute === link && selectedRouteStyles,
            !isOpen && "hidden",
          )}
        >
          <Link to={link} className="flex-1 text-white text-xs font-medium">
            {title}
          </Link>
        </Accordion.Content>
      ))}
    </Accordion.Panel>
  );
};

export default memo(NavGroup);
