import React, { useState } from "react";

import { Card, Label, TextInput } from "flowbite-react";
import Button from "components/Button";
import Header from "components/Header";
import { apiFetch } from "utils/api";

export default function CloudConnection({
  name,
  icon,
  key_a,
  key_b,
  key_c,
  docs,
  existing_connections,
  updateInfo,
}) {
  const [valA, setValA] = useState("");
  const [valB, setValB] = useState("");
  const [valC, setValC] = useState("");

  const [hovering, setHovering] = useState(false);
  const [showInputs, setShowInputs] = useState(false);

  const styl = {
    width: "20rem",
    cursor: "pointer",
    float: "left",
    minHeight: "7rem",
    backgroundColor: hovering ? "#eee" : "",
  };

  const key_names = [key_a, key_b, key_c].filter((v) => v !== undefined);
  const vals = [
    valA,
    key_b ? valB : undefined,
    key_c ? valC : undefined,
  ].filter((v) => v !== undefined);

  const all_set =
    existing_connections &&
    key_names.every((key) =>
      existing_connections.access_token_names.includes(key),
    );

  const handleCloudDisconnect = () => {
    console.log("handling CloudDisconnect. Clearing", key_names);
    return apiFetch("v0/org/creds", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ remove: key_names }),
    }).then(updateInfo);
  };
  const handleCloudConnect = () => {
    const cred_map = Object.fromEntries(
      key_names.map((key, i) => [key, vals[i]]),
    );
    console.log("handling CloudDisconnect. Adding", key_names, vals, cred_map);
    return apiFetch("v0/org/creds", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ add: cred_map }),
    }).then(updateInfo);
  };

  const handleCancel = () => setShowInputs(false);

  if (all_set) {
    return (
      <Card
        onMouseOver={(ev) => setHovering(true)}
        onMouseOut={(ev) => setHovering(false)}
        onClick={(ev) => setShowInputs(true)}
        className="mb-4 me-4"
        style={styl}
      >
        <div className="flex justify-start items-center">
          <img
              alt={name}
              src={icon}
              style={{ maxWidth: "50px" }}
              className="h-10 p-1 border rounded border-gray-300 bg-white mr-3"
            />
          <div>
            <Header lv={5}>{name}</Header>
            <Button variant="link" tooltip="Disconnect integration" onClick={(ev) => handleCloudDisconnect}>Disconnect</Button>
          </div>
        </div>
      </Card>
    );
  }

  if (showInputs) {
    return (
      <Card className="mb-4 me-4 p-4" style={styl}>
        <div className="my-3" style={{ width: "18rem" }}>
          <div>
            <Label>{key_a}</Label>
            <TextInput
              placeholder={key_a}
              value={valA}
              onChange={(ev) => setValA(ev.target.value)}
            />
          </div>
          {key_b && (
            <div>
              <Label>{key_b}</Label>
              <TextInput
                placeholder={key_b}
                value={valB}
                onChange={(ev) => setValB(ev.target.value)}
              />
            </div>
          )}
          {key_c && (
            <div>
              <Label>{key_c}</Label>
              <TextInput
                placeholder={key_c}
                value={valC}
                onChange={(ev) => setValC(ev.target.value)}
              />
            </div>
          )}
          <div className="grid grid-cols-2 gap-4 my-2">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleCloudConnect}>Connect</Button>
          </div>
          {docs && (
            <div className="flex flex-wrap">
              <a href={docs} target="BLANK">
                Documentation
              </a>
            </div>
          )}
        </div>
      </Card>
    );
  }

  return (
    <Card
      onMouseOver={(ev) => setHovering(true)}
      onMouseOut={(ev) => setHovering(false)}
      onClick={(ev) => setShowInputs(true)}
      className="mb-4 me-4"
      style={styl}
    >
      <div className="flex justify-start items-start">
        <img
          alt={name}
          src={icon}
          style={{ maxWidth: "50px" }}
          className="h-10 p-1 border rounded border-gray-300 bg-white mr-3"
        />
        <Header lv={5}>{name}</Header>
      </div>
    </Card>
  );
}
