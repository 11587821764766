const highlightSearchTerm = (text, search) => {
  const parts = text.split(new RegExp(`(${search})`, "gi"));

  return parts.map((part, index) =>
    part.toLowerCase() === search ? (
      <span key={index} className="bg-yellow-200">
        {part}
      </span>
    ) : (
      part
    ),
  );
};

export default function HighlightedText({ text, search }) {
  return search && text.toLowerCase().includes(search) ? (
    <div>{highlightSearchTerm(text, search)}</div>
  ) : (
    text
  );
}
