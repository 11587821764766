import React, { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import {
  Accordion,
  Card,
  Label,
  Spinner,
  Tabs,
  TextInput,
} from "flowbite-react";
import Header from "components/Header";
import ContentCard from "components/ContentCard";
import Button from "components/Button";
import FilterBar from "components/FilterBar";
import HighlightedText from "components/HighlightedText";

import { Spin } from "utils/util.js";
import { apiFetch } from "utils/api";
import { CustomTabTheme } from "utils/themes";

import {
  FaArrowsRotate,
  FaPlugCircleCheck,
  FaPlugCircleXmark,
  FaPlugCirclePlus,
  FaPlugCircleMinus,
} from "react-icons/fa6";
import { FaCheck } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";
import { BsShieldFillX } from "react-icons/bs";

import aws_icon from "images/aws-icon.png";
import gcp_icon from "images/gcp-icon.png";
import do_icon from "images/do-icon.png";
import azure_icon from "images/azure-icon.png";

function Collapsible({ header, body, className }) {
  return (
    <Accordion collapseAll className={{ className }}>
      <Accordion.Panel>
        <Accordion.Title>{header}</Accordion.Title>
        <Accordion.Content>{body}</Accordion.Content>
      </Accordion.Panel>
    </Accordion>
  );
}

function CloudscanSection({ counts, search, ix, k, v, readableName }) {
  const findings =
    v.findings &&
    (search
      ? Object.entries(v.findings)
      : Object.entries(v.findings).filter(([key, finding]) =>
          key.includes(search),
        ));

  //
  return (
    <Accordion
      collapseAll
      className="mb-3"
      theme={{
        title: {
          heading: "w-full text-gray-900",
          h2: "w-full",
        },
      }}
    >
      <Accordion.Panel>
        <Accordion.Title className="text-gray-900 hover:bg-indigo-100 hover:text-indigo-700">
          <div className="flex flex-wrap w-full">
            <div className="flex w-96">
              <HighlightedText text={k} search={search} />
            </div>
            <div className="flex mx-3 items-center justify-center text-sm">
              <div
                className={`flex items-center justify-center me-4 w-16 ${
                  counts["success"] === 0 ? "opacity-20" : ""
                }`}
              >
                <FaCheck className="text-green-500 me-1.5" />{" "}
                <span className="">{counts["success"]}</span>
              </div>
              <div
                className={`flex items-center justify-center me-4 w-16 ${
                  counts["warning"] === 0 ? "opacity-20" : ""
                }`}
              >
                <IoIosWarning className="text-yellow-400 me-1.5" />{" "}
                {counts["warning"]}
              </div>
              <div
                className={`flex items-center justify-center me-4 w-16 ${
                  counts["danger"] === 0 ? "opacity-20" : ""
                }`}
              >
                <BsShieldFillX className="text-red-600 me-1.5" />{" "}
                {counts["danger"]}
              </div>
            </div>
          </div>
        </Accordion.Title>
        <Accordion.Content className="p-4">
          <div>
            {(findings || []).map(([finding_name, finding], f_ix) => {
              const level =
                finding.flagged_items === 0 ? "success" : finding.level;
              const header = (
                <div className="flex flex-wrap">
                  {level === "success" ? (
                    <FaCheck className="me-1 mt-1 text-green-500" />
                  ) : level === "warning" ? (
                    <IoIosWarning className="me-1 mt-1 text-yellow-500" />
                  ) : (
                    <BsShieldFillX className="me-1 mt-1 text-red-600" />
                  )}
                  <span>
                    <HighlightedText text={finding_name} search={search} />
                  </span>
                </div>
              );
              if (level === "success") {
                return (
                  <div className="mb-2">
                    <Collapsible
                      header={header}
                      body={
                        <div className="w-full items-center justify-center text-center">
                          All checks passed!
                        </div>
                      }
                      className="mb-4"
                    />
                  </div>
                );
              }
              const Piece = ({ title, body, children }) =>
                body ? (
                  <div className="mb-5 ms-4">
                    <Label className="uppercase">{title}</Label>
                    <div>{children || body}</div>
                  </div>
                ) : (
                  ""
                );
              return (
                <Collapsible
                  className="mb-4"
                  header={header}
                  body={
                    <div>
                      <Piece title="Rationale" body={finding.rationale} />
                      <Piece title="Remediation" body={finding.remediation} />
                      <Piece title="References" body={finding.references}>
                        <ul className="list-disc ms-5">
                          {finding.references &&
                            finding.references.map((uri) => {
                              const url = new URL(uri);
                              const path = url.pathname
                                .split("/")
                                .filter((e) => e);
                              const name = path[path.length - 1].split(".")[0];
                              return (
                                <li>
                                  <a
                                    href={uri}
                                    target="BLANK"
                                    className="text-blue-500 underline hover:text-blue-300 hover:no-underline"
                                  >
                                    {name}
                                  </a>
                                </li>
                              );
                            })}
                        </ul>
                      </Piece>
                      <Piece title="Path" body={finding.path} />
                      <Piece title="Items" body={finding.items}>
                        {finding.items && (
                          <div>
                            <div>Flagged: {finding.flagged_items}</div>
                            <ul className="list-disc ms-5">
                              {finding.items.map((item) => (
                                <li>{readableName(item)}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </Piece>
                    </div>
                  }
                />
              );
            })}
          </div>
        </Accordion.Content>
      </Accordion.Panel>
    </Accordion>
  );
}

function CloudscanResult({ result, search, filters }) {
  const lookupName = (name) => {
    const split = name.split(".").slice(0, -1);
    const thing = split.reduce((acc, key) => acc && acc[key], result.services);
    return thing;
  };

  const readableName = (name) => {
    const thing = lookupName(name);

    if (thing && thing.name) {
      const prefix = thing.region || thing.AvailabilityZone || thing.project_id;
      if (prefix) {
        return `${prefix}:${thing.name}`;
      }
      return thing.name;
    }
    return name;
  };

  return (
    <ul>
      {result &&
        Object.entries(result.services || {}).map(([k, v], ix) => {
          const counts = { success: 0, warning: 0, danger: 0 };

          if (v.findings && v.findings !== {}) {
            Object.entries(v.findings).forEach(([_name, finding]) => {
              if (finding.flagged_items === 0) {
                counts.success += 1;
              } else {
                counts[finding.level] += 1;
              }
            });
          }

          if (
            counts.success === 0 &&
            counts.warning === 0 &&
            counts.danger === 0
          ) {
            return "";
          }

          if (filters.length && !filters.some((f) => counts[f])) {
            return "";
          }

          const searchHits =
            search &&
            (k.toLowerCase().includes(search) ||
              Object.keys(v.findings).some((key) =>
                key.toLowerCase().includes(search),
              ));

          if (search && !searchHits) {
            return "";
          }

          return (
            <li key={`${k}-${ix}`}>
              <CloudscanSection
                readableName={readableName}
                counts={counts}
                search={search}
                ix={ix}
                k={k}
                v={v}
              />
            </li>
          );
        })}
    </ul>
  );
}

function CloudConnectionInterface({
  name,
  icon,
  key_a,
  key_b,
  docs,
  onConnect,
}) {
  const [valA, setValA] = useState("");
  const [valB, setValB] = useState("");
  const [showInputs, setShowInputs] = useState(false);

  const key_names = [key_a, key_b].filter((v) => v !== undefined);
  const vals = [valA, key_b ? valB : undefined].filter((v) => v !== undefined);

  const handleCloudConnect = () => {
    const cred_map = Object.fromEntries(
      key_names.map((key, i) => [key, vals[i]]),
    );
    return apiFetch("v0/org/creds", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ add: cred_map }),
    }).then(onConnect);
  };

  const handleCancel = () => setShowInputs(false);

  if (showInputs) {
    return (
      <div className="my-3" style={{ width: "18rem" }}>
        <div>
          <Label>{key_a}</Label>
          <TextInput
            placeholder={key_a}
            value={valA}
            onChange={(ev) => setValA(ev.target.value)}
          />
        </div>
        {key_b && (
          <div>
            <Label>{key_b}</Label>
            <TextInput
              placeholder={key_b}
              value={valB}
              onChange={(ev) => setValB(ev.target.value)}
            />
          </div>
        )}
        <div className="grid grid-cols-2 gap-4 my-2">
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleCloudConnect}>Connect</Button>
        </div>
        {docs && (
          <div className="flex flex-wrap">
            <a
              href={docs}
              target="BLANK"
              className="text-blue-500 underline hover:text-blue-300 hover:no-underline"
            >
              Documentation
            </a>
          </div>
        )}
      </div>
    );
  }

  return (
    <Button icon={<FaPlugCirclePlus />} onClick={(ev) => setShowInputs(true)}>
      Configure integration
    </Button>
  );
}

function CloudscanTab({
  qClient,
  data,
  scanKey,
  handleRescan,
  label,
  tabIcon,
  credKeys,
  docLink,
}) {
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState([]);
  const singleScan = data.cloudscan[scanKey];
  const scanRunning = data.in_progress.includes(scanKey);

  const isConnected = credKeys.every((credKey) =>
    data.access_token_names.includes(credKey),
  );

  const filterMap = {
    Healthy: "success",
    Warnings: "warning",
    "Critical issues": "danger",
  };

  const handleDisconnect = () => {
    return apiFetch("v0/org/creds", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ remove: credKeys }),
    }).then((_) => qClient.invalidateQueries({ queryKey: ["cloudscanInfo"] }));
  };

  if (!isConnected) {
    return (
      <ContentCard
        title={`Wondering if you have vulnerabilities in your ${label} environment?`}
      >
        <p className="mb-5">
          Connect to your {label} environment to begin checking for common cloud
          vulnerabilities and misconfigurations.
        </p>
        <CloudConnectionInterface
          onConnect={(_) =>
            qClient.invalidateQueries({ queryKey: ["cloudscanInfo"] })
          }
          name={label}
          key_a={credKeys[0]}
          key_b={credKeys[1]}
          docs={docLink}
        />
      </ContentCard>
    );
  }

  return (
    <>
      <div className="flex flex-wrap mb-4">
        <div className="flex w-1/2">
          {!scanRunning && (
            <div>
              <FilterBar
                filters={{
                  Filters: ["Healthy", "Warnings", "Critical issues"],
                }}
                onSearch={(text) => setSearch(text.trim().toLowerCase())}
                onFilter={(filters) =>
                  setFilters(filters.Filters.map((f) => filterMap[f]))
                }
              />
            </div>
          )}
        </div>
        {singleScan && (
          <div className="flex w-1/2 justify-end items-center">
            <div>
              <div className="flex flex-end items-end justify-end">
                <Button
                  icon={<FaPlugCircleMinus />}
                  onClick={handleDisconnect}
                  isLoading={scanRunning}
                  className="mb-0 mr-6 me-6"
                  variant="link"
                >
                  Disconnect
                </Button>
              </div>

              <div className="text-sm text-gray-500 mr-6">
                Last scanned {singleScan.created}
              </div>
            </div>

            <div>
              <Button
                icon={<FaArrowsRotate />}
                onClick={handleRescan}
                isLoading={scanRunning}
                className="mb-0 mr-0 me-0"
              >
                Rescan
              </Button>
            </div>
          </div>
        )}
      </div>
      {singleScan ? (
        <Card>
          {scanRunning ? (
            <div className="flex flex-col items-center justify-center p-4">
              <Spinner className="w-40 h-40" />
              <Header lv={4}>A scan is in progress</Header>
              <p>
                Scans may take a few minutes depending on the complexity of your
                cloud infrastructure.
              </p>
            </div>
          ) : (
            <CloudscanResult
              result={singleScan.scan_result}
              filters={filters}
              search={search}
            />
          )}
        </Card>
      ) : (
        <div className="flex flex-col mx-5 py-5 items-center justify-center">
          <Header lv={3} className="mb-3">
            You've connected your {label} account, now scan for vulnerabilities!
          </Header>
          <p className="mb-5">
            Scans may take a few minutes depending on the complexity of your
            cloud infrastructure.
          </p>
          <Button
            icon={<FaArrowsRotate />}
            onClick={handleRescan}
            isLoading={scanRunning}
            className="h-14"
          >
            Scan for vulnerabilities
          </Button>
        </div>
      )}
    </>
  );
}

export default function CloudscanPage() {
  const qClient = useQueryClient();
  const { isPending, data } = useQuery({
    queryKey: ["cloudscanInfo"],
    queryFn: () =>
      apiFetch("v0/info", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          keys: ["access_token_names", "cloudscan", "in_progress"],
        }),
      }),
  });

  const startScan = (endpoint) => {
    qClient.invalidateQueries({ queryKey: ["cloudscanInfo"] });
    return apiFetch(`v0/${endpoint}`, { method: "POST" })
      .then(console.log)
      .then((_) => qClient.invalidateQueries({ queryKey: ["cloudscanInfo"] }));
  };

  if (isPending) {
    return <Spin />;
  }

  function TabElem({ label, icon, scanKey, credKeys, handleRescan, docLink }) {
    return (
      <Tabs.Item
        title={
          <>
            <img
              src={icon}
              alt={`Icon for cloud connect ${label}`}
              className="w-4 me-2"
            />
            {label}
            {credKeys.every((k) => data.access_token_names.includes(k)) ? (
              <FaPlugCircleCheck className="ms-2" />
            ) : (
              <FaPlugCircleXmark className="ms-2" />
            )}
          </>
        }
      >
        <CloudscanTab
          data={data}
          qClient={qClient}
          scanKey={scanKey}
          handleRescan={handleRescan}
          label={label}
          icon={icon}
          credKeys={credKeys}
          docLink={docLink}
        />
      </Tabs.Item>
    );
  }

  return (
    <div className="flex flex-col space-y-6">
      <Header lv={6}>Cloud scan</Header>

      <Tabs variant="underline" theme={CustomTabTheme}>
        {/* FIXME -
           I have to call these as functions for some reason. When I try to invoke them as elements,
           I start getting `String contains an invalid character` errors. Fix it if you can. */}
        {TabElem({
          scanKey: "scoutsuite_aws",
          handleRescan: (_) => startScan("cloud-scan/aws"),
          label: "AWS",
          icon: aws_icon,
          credKeys: ["aws_access_key_id", "aws_secret_access_key"],
          docLink:
            "https://www.virtuesecurity.com/kb/create-an-aws-read-only-access-token/",
        })}
        {TabElem({
          scanKey: "scoutsuite_gcp",
          handleRescan: (_) => startScan("cloud-scan/gcp"),
          label: "Google Cloud Platform",
          icon: gcp_icon,
          credKeys: ["gcp_service_account_key"],
          docLink: "https:cloud.google.com/iam/docs/keys-create-delete",
        })}
        {TabElem({
          scanKey: "scoutsuite_do",
          handleRescan: (_) => startScan("cloud-scan/digital-ocean"),
          label: "Digital Ocean",
          icon: do_icon,
          credKeys: ["do_token"],
          docLink:
            "https:docs.digitalocean.com/reference/api/create-personal-access-token/#creating-a-token",
        })}
        {TabElem({
          scanKey: "scoutsuite_azure",
          handleRescan: (_) => startScan("cloud-scan/azure"),
          label: "Microsoft Azure",
          icon: azure_icon,
          credKeys: ["azure_client_secret"],
        })}
      </Tabs>
    </div>
  );
}
