import React, { useState } from "react";

import { Modal } from "flowbite-react";

import Button from "components/Button";

export default function ConfirmModal({label, icon, variant, header, children, onConfirm, confirmLabel}) {
  const [open, setOpen] = useState(false);
  return <>
           <Button onClick={_ => setOpen(true)} icon={icon} variant={variant}>{label}</Button>
           <Modal dismissible show={open} onClose={_ => setOpen(false)}>
             {header && <Modal.Header>{header}</Modal.Header>}
             <Modal.Body>
               {children}
             </Modal.Body>
             <Modal.Footer>
               <Button onClick={_ => {
                 onConfirm();
                 return setOpen(false);
               }}>
                 {confirmLabel || "Confirm"}
               </Button>
               <Button onClick={_ => setOpen(false)} variant="light">
                 Cancel
               </Button>
             </Modal.Footer>
           </Modal>
         </>;
}
