import React, { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { fetchEmployeeList } from "utils/api";
import { getToken } from "utils/util";

import Button from "components/Button";
import Table from "components/Table";
import Header from "components/Header";
import PersonDrawer from "pages/PeoplePage/PersonDrawer";
import PeopleStatusIndicator from "pages/PeoplePage/PeopleStatusIndicator";

import { FaPlus } from "react-icons/fa6";
import { GoDash } from "react-icons/go";
import { IoEyeOutline, IoShieldCheckmark } from "react-icons/io5";
import { BiEdit, BiSolidShieldX } from "react-icons/bi";
import { HiMiniTrash } from "react-icons/hi2";

import { LuTrash2 } from "react-icons/lu";
import { LiaUserEditSolid } from "react-icons/lia";
import DotDotDotMenu from "components/DotDotDotMenu";

function EmployeeDropdown({ employee, isLoading, handleView, handleUpdate }) {
  return (
    <DotDotDotMenu
      isLoading={isLoading}
      items={[
        [
          <IoEyeOutline />,
          "View",
          () => {
            handleView(employee);
          },
        ],
        [
          <LiaUserEditSolid />,
          "Update details",
          () => {
            handleUpdate(employee);
          },
        ],
        [<BiEdit />, "Manager computers", () => {}],
        [<LuTrash2 />, "Deactivate person", () => {}],
      ]}
    />
  );
}

export default function PeoplePage() {
  const user = getToken().user;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState({});

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const deselectEmployee = () => {
    setSelectedEmployee({});
  };

  const handleView = (emp) => {
    navigate(`/organization/person?email=${emp?.email}`);
  };

  const handleUpdate = (emp) => {
    setIsDrawerOpen(true);
    setSelectedEmployee(emp);
  };

  let employees = [];
  const { isPending, error, data } = useQuery({
    queryKey: ["employeeInfo", user.org],
    queryFn: () => {
      return fetchEmployeeList(user.org);
    },
    retry: 1,
    onError: (error) => {
      console.error("Failed to fetch employees:", error);
    },
    enabled: !!user.org,
  });

  if (data) {
    employees = data;
  }

  const complianceCheck = (employee) => {
    if (employee?.deleted) {
      return (
        <div className="inline-flex items-center justify-center bg-gray-100 text-gray-700 text-sm font-medium rounded px-2 py-1 space-x-1">
          <HiMiniTrash className="text-sm" />{" "}
          <span className="status-text">Deactivated</span>
        </div>
      );
    } else if (employee?.policies_sign_off && employee?.background_check) {
      return (
        <div className="inline-flex items-center justify-center bg-green-100 text-green-600 text-sm font-medium rounded px-2 py-1 space-x-1">
          <IoShieldCheckmark className="text-sm" />{" "}
          <span className="status-text">Compliant</span>
        </div>
      );
    } else {
      return (
        <div className="inline-flex items-center justify-center bg-red-100 text-red-700 text-sm font-medium rounded px-2 py-1 space-x-1">
          <BiSolidShieldX className="text-base" />
          <span className="status-text">Not compliant</span>
        </div>
      );
    }
  };
  return (
    <div className="flex w-full flex-col ">
      <div className="flex w-full flex-row mb-4">
        <Header lv={6} className={"my-4 text-base"}>
          People
        </Header>
        <div className=" ml-auto">
          <Button
            onClick={() => setIsDrawerOpen(true)}
            variant={"indigo"}
            icon={<FaPlus />}
            className="!me-0 !mb-0 !text-xs"
          >
            Add Person
          </Button>
          <PersonDrawer
            org_id={user.org}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            selectedEmployee={selectedEmployee}
            deselectEmployee={deselectEmployee}
          />
        </div>
      </div>
      <div>
        <Table
          headers={[
            "Person",
            "Email",
            "Status",
            "Background",
            "Policies",
            "Device",
            "Training",
            "",
          ]}
          rows={employees.map((emp) => {
            return [
              <div>
                <Button
                  variant="link"
                  onClick={(e) => {
                    navigate(`/organization/person?email=${emp?.email}`);
                  }}
                >
                  {emp?.name}
                </Button>
                <p class="text-xs text-gray-500">{emp?.job_title}</p>
              </div>,
              <p>{emp?.email}</p>,
              complianceCheck(emp),
              <div className="flex items-center justify-center">
                {emp?.deleted ? (
                  <span className="text-gray-400">—</span>
                ) : (
                  <PeopleStatusIndicator status={emp?.background_check} />
                )}
              </div>,
              <div className="flex items-center justify-center">
                {emp?.deleted ? (
                  <span className="text-gray-400">—</span>
                ) : (
                  <PeopleStatusIndicator status={emp?.policies_sign_off} />
                )}
              </div>,
              <div className="flex items-center justify-center">
                {emp?.deleted ? (
                  <span className="text-gray-400">—</span>
                ) : (
                  <PeopleStatusIndicator />
                )}
              </div>,
              <div className="flex items-center justify-center">
                {emp?.deleted ? (
                  <span className="text-gray-400">—</span>
                ) : (
                  <PeopleStatusIndicator />
                )}
              </div>,
              <EmployeeDropdown
                employee={emp}
                isLoading={isPending}
                handleView={handleView}
                handleUpdate={handleUpdate}
              />,
            ];
          })}
        />
      </div>
    </div>
  );
}
