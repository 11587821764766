export default function FrameworkCard({ icon, framework, version }) {
  return (
    <div className="flex flex-wrap space-x-2">
      <div className="flex items-center justify-center">
        <div className="rounded p-1 border border-gray-300 rounded">
          <img
            src={icon}
            className="w-7 h-7 rounded"
            alt={`Icon for the ${framework} framework`}
          />
        </div>
      </div>
      <div className="flex flex-col justify-center">
        <div className="text-sm font-medium">{framework}</div>
        {version && (
          <p className="text-xs font-medium text-gray-500">{version}</p>
        )}
      </div>
    </div>
  );
}
