import { useQuery } from "@tanstack/react-query";

import { Avatar } from "flowbite-react";
import { FaChevronRight } from "react-icons/fa";

import { getToken } from "utils/util";
import { apiFetch } from "utils/api";

function UserInfo() {
  const jwt = getToken();

  const employee = useQuery({
    queryKey: ["selfInfo"],
    queryFn: () => apiFetch("v0/self")
  });

  return (
    <div className="flex justify-between items-center border-t border-gray-500 p-4 px-6 w-full space-x-4">
      <div className="flex items-center gap-4">
        <div className="size-6">
          <Avatar img="https://picsum.photos/200" rounded size={6} />
        </div>
        <span className="text-white text-sm text-ellipsis line-clamp-1">
          {(employee
            && employee.data
            && employee.data.employee?.name)
           || jwt.user.email}
        </span>
      </div>
      <FaChevronRight className="h-4 w-4 text-white" />
    </div>
  );
}

export default UserInfo;
