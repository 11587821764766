import React from "react";

import Header from "components/Header";
import Badge from "components/Badge";

import { RiLockPasswordLine } from "react-icons/ri";

export default function PasswordManagerPage() {
  return (
    <div className="h-auto">
      <Header lv={6} className={"mb-6 text-base"}>
        Password Manager
      </Header>

      <div className="flex flex-col max-w border border-1 border-gray-200 bg-white rounded-lg shadow-md p-6 min-h-96 justify-center items-center">
        <div className="flex flex-col max-w-[400px] justify-center items-center">
          <RiLockPasswordLine className="w-20 h-20 mb-4 text-custom-dark-green" />

          <h1 className="text-xl font-semibold">Password Manager</h1>
          <Badge icon="" text="COMING SOON" color="yellow" className="mb-2" />

          <p className="text-sm text-gray-600 text-center">
            Easily manage and safeguard your orginaztions' passwords in one
            place. Our password manager helps you create, store, and auto-fill
            secure passwords for all your accounts, ensuring your online safety
            and peace of mind.
          </p>
        </div>
      </div>
    </div>
  );
}
